import { useIsFocused, useNavigation } from '@react-navigation/native';
import { Button, Icon, Spinner, Text, useTheme } from '@ui-kitten/components';
import { ModelCreationData } from 'mobx-keystone';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ActivityIndicator, FlatList, Pressable, View } from 'react-native';

import AssessmentItem from '../../components/Assessments/AssessmentItem';
import AssessmentLabels from '../../components/Assessments/AssessmentLabels';
import CustomTooltip from '../../components/Common/CustomTooltip';
import ShowFilters from '../../components/Common/ShowFilters';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import useUserDefaultDetails from '../../hooks/useUserDefaultDetails';
import Assessment from '../../models/Assessment';
import { AppStackNavigation } from '../../navigation';
import { useStore } from '../../stores';
import { FilterData } from '../../types';

const Assessments: React.FC = () => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const navigation = useNavigation<AppStackNavigation>();
  const store = useStore();
  const {
    assessmentStore,
    isAssessmentsChanged,
    setIsAssessmentsChanged,
    isFromAssessment,
    setIsFromAssessment,
    isInternetReachable,
    isGlobalAutosaving,
    setCurrentlyEditingAssessment,
  } = store;
  const isFocused = useIsFocused();
  const theme = useTheme();
  const userDefaultDetails = useUserDefaultDetails();

  const [showTip, setShowTip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFooter, setLoadingFooter] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [assessments, setAssessments] = useState<
    ModelCreationData<Assessment>[]
  >([]);
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const page = useRef(-1);
  const loadingRef = useRef(false);
  const filters = store.filters || {};

  const userAssessments = useMemo(() => {
    return assessmentStore
      .getAssessments()
      .filter(
        (assessment) =>
          assessment.isDraft &&
          (assessment.assessor === Number(userDefaultDetails.id) ||
            assessment.creator === Number(userDefaultDetails.id)),
      );
  }, [assessmentStore.assessments.values()]);

  const onFilterSubmit = async (data: FilterData) => {
    if (
      loading ||
      loadingRef.current ||
      loadingFooter ||
      !isInternetReachable
    ) {
      return;
    }

    loadingRef.current = true;
    setLoading(true);

    page.current = 1;
    const result = await assessmentStore.fetchAssessments(data, page.current);
    if (result.ok) {
      setAssessments(result.extra?.results as ModelCreationData<Assessment>[]);

      if (result.extra && result.extra.next) {
        page.current += 1;
      } else {
        page.current = -1;
      }

      if (result.extra?.count) {
        setTotalCount(result.extra.count);
      }
    } else {
      page.current = -1;
      setLoadingFooter(false);
    }

    loadingRef.current = false;
    setLoading(false);
  };

  const fetchNextAssessments = async () => {
    if (
      loading ||
      loadingFooter ||
      loadingRef.current ||
      page.current === -1 ||
      !isInternetReachable
    ) {
      return;
    }

    setLoadingFooter(true);

    const result = await assessmentStore.fetchAssessments(
      filters,
      page.current,
    );

    if (result.ok) {
      setAssessments([
        ...assessments,
        ...(result.extra?.results as ModelCreationData<Assessment>[]),
      ]);

      if (result.extra?.next === null) {
        page.current = -1;
      } else {
        page.current += 1;
      }
    } else {
      page.current = -1;
    }

    setLoadingFooter(false);
  };

  useEffect(() => {
    if (isFocused && isInternetReachable) {
      if (isAssessmentsChanged || !isFromAssessment) {
        onFilterSubmit(filters);
        setIsAssessmentsChanged(false);
      } else if (isFromAssessment) {
        setIsFromAssessment(false);
      }
      setCurrentlyEditingAssessment(-1);
    }
  }, [isFocused]);

  useEffect(() => {
    if (refresh) {
      onFilterSubmit(filters);
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    if (!isInternetReachable) {
      setAssessments(userAssessments);
    } else {
      onFilterSubmit(filters);
    }
  }, [isInternetReachable]);

  const renderItem = useCallback(
    ({ item }: any) => (
      <AssessmentItem
        assessment={item}
        cameFrom="Assessments table"
        setRefresh={setRefresh}
      />
    ),
    [],
  );

  const renderEmptyAssessments = useCallback(
    () =>
      loading || isGlobalAutosaving ? (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color={theme['bp-green']} />
        </View>
      ) : (
        <Text style={styles.emptyAssessments}>No assessments found</Text>
      ),
    [loading, isGlobalAutosaving],
  );

  const renderFooter = () => {
    if (loading || assessments.length === 0) {
      return;
    }

    return (
      <View style={styles.footerContainer}>
        {totalCount ? (
          <Text style={styles.footerSummary}>
            Showing {assessments.length} of {totalCount} assessments
          </Text>
        ) : (
          <></>
        )}
        {page.current !== -1 ? (
          <Button
            status="primary"
            appearance="filled"
            accessoryLeft={(props) =>
              loadingFooter ? (
                <Spinner status="info" {...props} />
              ) : (
                <Icon
                  name="plus-outline"
                  fill={theme['text-white']}
                  {...props}
                />
              )
            }
            onPress={() => fetchNextAssessments()}
          >
            Load more assessments
          </Button>
        ) : (
          <></>
        )}
      </View>
    );
  };

  const renderHeader = () => (
    <>
      {isInternetReachable && <ShowFilters onFilterSubmit={onFilterSubmit} />}
      <AssessmentLabels />
    </>
  );

  return (
    <>
      <View style={styles.container}>
        <FlatList
          data={loading || isGlobalAutosaving ? [] : assessments}
          renderItem={renderItem}
          contentContainerStyle={[{ paddingBottom: 80, paddingRight: 26 }]}
          keyExtractor={(item) => `${item.id}`}
          ListHeaderComponent={renderHeader()}
          ListEmptyComponent={renderEmptyAssessments()}
          ListFooterComponent={renderFooter()}
        />
      </View>
      {isInternetReachable && !isGlobalAutosaving && (
        <View style={styles.addAssessmentsContainer}>
          <CustomTooltip
            showTip={showTip}
            contentText="Start a new assessment"
            setShowTip={setShowTip}
            placement="top"
            multiline
          >
            <Pressable
              onPress={() => {
                navigation.navigate('Add Assessments', {
                  cameFrom: 'Assessments table',
                });
              }}
              onLongPress={() => setShowTip(true)}
              // @ts-ignore
              onMouseEnter={() => setShowTip(true)}
              // @ts-ignore
              onMouseLeave={() => setShowTip(false)}
              style={styles.addAssessmentsButton}
            >
              <View>
                <Icon
                  name="plus-outline"
                  width={84}
                  height={84}
                  fill="#FFFFFF"
                />
              </View>
            </Pressable>
          </CustomTooltip>
        </View>
      )}
    </>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
      paddingLeft: 26,
      paddingBottom: 16,
      backgroundColor: '#F2F2F2',
    },
    addAssessmentsContainer: {
      position: 'absolute',
      bottom: 50,
      right: 50,
    },
    addAssessmentsButton: {
      alignItems: 'center',
      justifyContent: 'center',
      width: 120,
      height: 120,
      borderRadius: 100,
      backgroundColor: '#007F00',
    },
    emptyAssessments: {
      textAlign: 'center',
      marginTop: 20,
      fontFamily: 'UniversLTPro_Regular',
    },
    loading: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    footerContainer: {
      marginTop: 10,
      alignItems: 'center',
    },
    footerSummary: {
      fontSize: 14,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: '#232323',
      marginBottom: 10,
    },
  },
});

export default observer(Assessments);
