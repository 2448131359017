import localForage from 'localforage';

import { StorageService } from './storageService';

export class NativeStorageService extends StorageService {
  storageInstance = localForage.createInstance({
    driver: [
      localForage.INDEXEDDB,
      localForage.WEBSQL,
      localForage.LOCALSTORAGE,
    ],
    name: 'bp-ewells',
  });

  getItemAsync = async (key: string): Promise<string | null> => {
    try {
      const value = await this.storageInstance.getItem(key);
      return value as string | null;
    } catch (error) {
      console.error(`Failed to get item ${key}:`, error);
      return null;
    }
  };

  setItemAsync = async (key: string, item: string): Promise<void> => {
    try {
      await this.storageInstance.setItem(key, item);
    } catch (error) {
      console.error(`Failed to set item ${key}:`, error);
    }
  };

  removeItemAsync = async (key: string): Promise<void> => {
    try {
      await this.storageInstance.removeItem(key);
    } catch (error) {
      console.error(`Failed to remove item ${key}:`, error);
    }
  };

  clearAsync = async (): Promise<void> => {
    try {
      await this.storageInstance.clear();
    } catch (error) {
      console.error('Failed to clear storage:', error);
    }
  };

  getAllKeys = async (): Promise<string[] | null> => {
    try {
      return await this.storageInstance.keys();
    } catch (error) {
      console.error('Failed to get all keys:', error);
      return null;
    }
  };

  removeAllSets = async (prefix: string): Promise<void> => {
    try {
      const keys = await this.storageInstance.keys();
      const matchingKeys = keys.filter((key: string) => key.startsWith(prefix));
      await Promise.all(matchingKeys.map((key) => this.removeSet(key)));
    } catch (error) {
      console.error(`Failed to remove all sets with prefix ${prefix}:`, error);
    }
  };

  addToSet = async (
    setId: string,
    key: string,
    item: string,
  ): Promise<void> => {
    try {
      await this.storageInstance.setItem(key, item);
      let set: string[] = (await this.storageInstance.getItem(setId)) || [];
      if (!Array.isArray(set)) {
        set = [];
      }
      set.push(key);
      await this.storageInstance.setItem(setId, set);
    } catch (error) {
      console.error(`Failed to add item to set ${setId}:`, error);
    }
  };

  removeSet = async (setId: string): Promise<void> => {
    try {
      const set = await this.storageInstance.getItem(setId);
      if (!set) {
        return;
      }
      await Promise.all(
        (set as string[]).map((key) => this.storageInstance.removeItem(key)),
      );
      await this.storageInstance.removeItem(setId);
    } catch (error) {
      console.error(`Failed to remove set ${setId}:`, error);
    }
  };
}
