import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  objectMap,
  getRoot,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import Store from './Store';
import AssessmentSchedule from '../models/AssessmentSchedule';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('bpEwells/AssessmentScheduleStore')
export default class AssessmentScheduleStore extends Model({
  assessmentSchedules: prop(() => objectMap<AssessmentSchedule>()),
}) {
  @observable
  loading = false;

  getAssessmentSchedule = (id: number): AssessmentSchedule | undefined => {
    return this.assessmentSchedules.get(`${id}`);
  };

  getAssessmentSchedules = (): AssessmentSchedule[] => {
    return Array.from(this.assessmentSchedules.values());
  };

  @modelAction
  createOrUpdateAssessmentSchedule(
    data: ModelCreationData<AssessmentSchedule>,
  ) {
    const id = `${data.id}`;

    let assessmentSchedule: AssessmentSchedule;
    if (this.assessmentSchedules.has(id)) {
      assessmentSchedule = this.assessmentSchedules.get(id)!;
    } else {
      assessmentSchedule = new AssessmentSchedule(data);
      this.assessmentSchedules.set(id, assessmentSchedule);
    }

    assessmentSchedule.update(data);
  }

  @modelFlow
  fetchAssessmentSchedules = _async(function* (this: AssessmentScheduleStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<AssessmentSchedule>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchAssessmentSchedules(rootStore.authStore.accessToken),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching assessment schedules', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateAssessmentSchedule(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchAssessmentSchedulesSite = _async(function* (
    this: AssessmentScheduleStore,
    site: number | undefined,
    year: number | undefined,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let entities: any;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.fetchAssessmentSchedulesSite(
          rootStore.authStore.accessToken,
          site,
          year,
        ),
      ));
    } catch (error) {
      console.warn(
        '[DEBUG] error fetching assessment schedules for site',
        error,
      );
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    this.loading = false;
    return getSuccess(entities);
  });

  @modelFlow
  assignAssessor = _async(function* (
    this: AssessmentScheduleStore,
    id: string,
    assessor: number,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let entities: any;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.assignAssessor(rootStore.authStore.accessToken, id, assessor),
      ));
      if (entities) {
        this.createOrUpdateAssessmentSchedule(entities);
      }
    } catch (error) {
      console.warn('[DEBUG] error submitting assessment schedule', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  setNotApplicable = _async(function* (
    this: AssessmentScheduleStore,
    id: string,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let entities: any;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.setNotApplicable(rootStore.authStore.accessToken, id),
      ));
      if (entities) {
        this.createOrUpdateAssessmentSchedule(entities);
      }
    } catch (error) {
      console.warn('[DEBUG] error submitting assessment schedule', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });
}
