import { format, parseISO } from 'date-fns';
import { cloneDeep, isEmpty } from 'lodash';
import qs from 'qs';
import { default as snakeCaseKeys } from 'snakecase-keys';

import { callApiWithToken } from './base';
import config from '../../config';
import {
  AnswerData,
  AssessmentData,
  AttachmentDeleteForm,
  FilterData,
} from '../../types';

export const addAssessment = async (
  token: string,
  data: AssessmentData,
  deviceInfo: { [key: string]: string },
) =>
  callApiWithToken(
    config.urls.assessments,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys({ ...data, deviceInfo })),
  );

export const addAnswer = async (
  token: string,
  data: AnswerData | AnswerData[],
) =>
  callApiWithToken(
    config.urls.answers,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const addAttachments = async (token: string, data: any[]) => {
  return callApiWithToken(
    `${config.urls.attachments}`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );
};

export const updateAssessment = async (
  token: string,
  data: AssessmentData,
  deviceInfo: { [key: string]: string },
  autosave: boolean,
  id: number,
) =>
  callApiWithToken(
    `${config.urls.assessments}${id}/`,
    token,
    'PUT',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys({ ...data, deviceInfo, autosave })),
  );

export const updateAnswer = async (
  token: string,
  data: AnswerData | AnswerData[],
) =>
  callApiWithToken(
    `${config.urls.updateAnswers}`,
    token,
    'PUT',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const deleteAssessment = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.assessments}${id}/`, token, 'DELETE');

export const deleteAttachments = async (
  token: string,
  data: AttachmentDeleteForm[],
) =>
  callApiWithToken(
    `${config.urls.deleteAttachments}`,
    token,
    'DELETE',
    { 'Content-Type': 'application/json' },
    JSON.stringify(snakeCaseKeys(data)),
  );

export const fetchAssessments = async (
  token: string,
  filters: FilterData,
  pageNum: number = 1,
) => {
  let dateFilters = '';
  const paramFilters = cloneDeep(filters);
  if (filters.dateStart) {
    dateFilters += `&date_start=${format(
      parseISO(filters.dateStart),
      'MM/dd/yyyy',
    )}`;
    paramFilters.dateStart = undefined;
  }
  if (filters.dateEnd) {
    dateFilters += `&date_end=${format(
      parseISO(filters.dateEnd),
      'MM/dd/yyyy',
    )}`;
    paramFilters.dateEnd = undefined;
  }
  const url = `${config.urls.assessments}?page=${pageNum}`;
  const params = Object.fromEntries(
    Object.entries(paramFilters).filter(([k, v]) => v),
  );
  return callApiWithToken(
    `${url}${isEmpty(params) ? '' : '&'}${qs.stringify(
      snakeCaseKeys(params),
    )}${dateFilters}`,
    token,
    'GET',
  );
};

export const fetchUserDraftAssessments = async (token: string) =>
  callApiWithToken(`${config.urls.userDrafts}`, token, 'GET');

export const fetchAssessmentDetails = async (token: string, id: number) =>
  callApiWithToken(`${config.urls.assessments}${id}/details/`, token, 'GET');

export const fetchAssessmentHistoricalDetails = async (
  token: string,
  id: number,
) =>
  callApiWithToken(
    `${config.urls.assessments}${id}/historical-details/`,
    token,
    'GET',
  );

export const fetchAnswers = async (token: string) =>
  callApiWithToken(`${config.urls.answers}`, token, 'GET');

export const fetchAttachments = async (token: string) =>
  callApiWithToken(`${config.urls.attachments}`, token, 'GET');

export const fetchActionItems = async (
  token: string,
  filters: FilterData,
  pageNum: number = 1,
) => {
  let dateFilters = '';
  const paramFilters = cloneDeep(filters);
  if (filters.dateStart) {
    dateFilters += `&date_start=${format(
      parseISO(filters.dateStart),
      'MM/dd/yyyy',
    )}`;
    paramFilters.dateStart = undefined;
  }
  if (filters.dateEnd) {
    dateFilters += `&date_end=${format(
      parseISO(filters.dateEnd),
      'MM/dd/yyyy',
    )}`;
    paramFilters.dateEnd = undefined;
  }
  const url = `${config.urls.actionItems}?page=${pageNum}`;
  const params = Object.fromEntries(
    Object.entries(paramFilters).filter(([k, v]) => v),
  );
  return callApiWithToken(
    `${url}${isEmpty(params) ? '' : '&'}${qs.stringify(
      snakeCaseKeys(params),
    )}${dateFilters}`,
    token,
    'GET',
  );
};

export const fetchStatistics = async (
  token: string,
  filters: FilterData,
  pageNum: number = 1,
) => {
  let dateFilters = '';
  const paramFilters = cloneDeep(filters);
  if (filters.dateStart) {
    dateFilters += `&date_start=${format(
      parseISO(filters.dateStart),
      'MM/dd/yyyy',
    )}`;
    paramFilters.dateStart = undefined;
  }
  if (filters.dateEnd) {
    dateFilters += `&date_end=${format(
      parseISO(filters.dateEnd),
      'MM/dd/yyyy',
    )}`;
    paramFilters.dateEnd = undefined;
  }
  const url = `${config.urls.statistics}?page=${pageNum}`;
  const params = Object.fromEntries(
    Object.entries(paramFilters).filter(([k, v]) => v),
  );
  return callApiWithToken(
    `${url}${isEmpty(params) ? '' : '&'}${qs.stringify(
      snakeCaseKeys(params),
    )}${dateFilters}`,
    token,
    'GET',
  );
};
