import './utils/gesture-handler';
import * as eva from '@eva-design/eva';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, KeyboardAvoidingView, View } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import Colors from './constants/Colors';
import Mapping from './constants/Mapping';
import useColorScheme from './hooks/useColorScheme';
import useInit from './hooks/useInit';
import Navigation from './navigation';
import { StoreProvider } from './stores';
import CastrolLogo from '../assets/images/castrol_vector_logo.svg';
import EverifyLogo from '../assets/images/everify_logo_gradient.svg';

function App() {
  const { store, ready } = useInit();
  const colorScheme = useColorScheme();
  const statusBar = colorScheme === 'dark' ? 'light' : 'dark';

  if (!ready || !store) {
    return (
      <View style={styles.loading}>
        <View style={styles.logoContainer}>
          <View style={{ paddingTop: 3 }}>
            <CastrolLogo width={97} height={23} />
          </View>
          <View style={styles.logoBorder} />
          <EverifyLogo height={30} />
        </View>
      </View>
    );
  }

  return (
    <>
      <IconRegistry icons={EvaIconsPack} />
      <StatusBar style={statusBar} />
      <ApplicationProvider
        mapping={eva.mapping}
        customMapping={Mapping}
        theme={Colors[colorScheme]}
      >
        <SafeAreaProvider>
          <ActionSheetProvider>
            <StoreProvider value={store}>
              <KeyboardAvoidingView style={styles.container}>
                <Navigation />
              </KeyboardAvoidingView>
            </StoreProvider>
          </ActionSheetProvider>
        </SafeAreaProvider>
      </ApplicationProvider>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  loading: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoContainer: {
    width: 196,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoBorder: {
    width: 1,
    height: 29,
    backgroundColor: '#007F00',
    marginRight: 7.5,
    marginLeft: 5,
  },
});

export default App;
