import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  objectMap,
  getRoot,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import Store from './Store';
import Site from '../models/Site';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('bpEwells/SiteStore')
export default class SiteStore extends Model({
  sites: prop(() => objectMap<Site>()),
}) {
  @observable
  loading = false;

  getSite = (id: number): Site | undefined => {
    return this.sites.get(`${id}`);
  };

  getSites = (): Site[] => {
    return Array.from(this.sites.values());
  };

  getActiveSites = (performanceUnitId?: number): Site[] => {
    if (performanceUnitId) {
      return Array.from(this.sites.values()).filter(
        (s) => s.isActive && s.performanceUnit === performanceUnitId,
      );
    }
    return Array.from(this.sites.values()).filter((s) => s.isActive);
  };

  @modelAction
  createOrUpdateSite(data: ModelCreationData<Site>) {
    const id = `${data.id}`;

    let site: Site;
    if (this.sites.has(id)) {
      site = this.sites.get(id)!;
    } else {
      site = new Site(data);
      this.sites.set(id, site);
    }

    site.update(data);
  }

  @modelFlow
  fetchSites = _async(function* (this: SiteStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<Site>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchSites(rootStore.authStore.accessToken)));
    } catch (error) {
      console.warn('[DEBUG] error fetching sites', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateSite(data));

    this.loading = false;
    return getSuccess();
  });
}
