import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  objectMap,
  getRoot,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import Store from './Store';
import Action from '../models/Action';
import CatAction from '../models/CatAction';
import * as api from '../services/api';
import { ActionData, CatActionTrackerItem, FilterData } from '../types';
import { getError, getSuccess } from '../utils/models';

@model('bpEwells/ActionStore')
export default class ActionStore extends Model({
  actions: prop(() => objectMap<Action>()),
  catActions: prop(() => objectMap<CatAction>()),
}) {
  @observable
  loading = false;

  getAction = (id: number): Action | undefined => {
    return this.actions.get(`${id}`);
  };

  getActions = (): Action[] => {
    return Array.from(this.actions.values());
  };

  getCatAction = (id: number): CatAction | undefined => {
    return this.catActions.get(`${id}`);
  };

  getCatActions = (): CatAction[] => {
    return Array.from(this.catActions.values());
  };

  @modelAction
  createOrUpdateAction(data: ModelCreationData<Action>) {
    const id = `${data.id}`;

    let action: Action;
    if (this.actions.has(id)) {
      action = this.actions.get(id)!;
    } else {
      action = new Action(data);
      this.actions.set(id, action);
    }

    action.update(data);
  }

  @modelAction
  createOrUpdateCatAction(data: CatActionTrackerItem) {
    const id = `${data.id}`;

    let catAction: CatAction;
    if (this.catActions.has(id)) {
      catAction = this.catActions.get(id)!;
    } else {
      catAction = new CatAction(data);
      this.catActions.set(id, catAction);
    }

    catAction.update(data);
  }

  @modelFlow
  fetchActions = _async(function* (this: ActionStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<Action>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchActions(rootStore.authStore.accessToken)));
    } catch (error) {
      console.warn('[DEBUG] error fetching actions', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }
    results.forEach((data) => this.createOrUpdateAction(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchCatActions = _async(function* (
    this: ActionStore,
    filters: FilterData,
    pageNum: number,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let next: string;
    let count: string;
    let results: CatActionTrackerItem[];
    try {
      ({
        response: {
          entities: { count, next, results },
        },
      } = yield* _await(
        api.fetchCatActions(rootStore.authStore.accessToken, filters, pageNum),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching cat actions', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }
    results.forEach((data) => this.createOrUpdateCatAction(data));
    this.loading = false;
    return getSuccess({ results, next, count });
  });

  @modelFlow
  refreshCatActions = _async(function* (this: ActionStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    try {
      yield* _await(api.refreshCatActions(rootStore.authStore.accessToken));
    } catch (error) {
      console.warn('[DEBUG] error refreshing cat actions', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    rootStore.setIsCatActionsChanged(true);

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  addAction = _async(function* (this: ActionStore, data: ActionData) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let newAction: ModelCreationData<Action>;
    try {
      ({
        response: { entities: newAction },
      } = yield* _await(api.addAction(rootStore.authStore.accessToken, data)));
    } catch (error) {
      console.warn('[DEBUG] error creating action', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }
    this.createOrUpdateAction(newAction);

    this.loading = false;
    return getSuccess({ id: newAction.id });
  });

  @modelFlow
  updateAction = _async(function* (
    this: ActionStore,
    id: number,
    data: ActionData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let entities: any;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.updateAction(rootStore.authStore.accessToken, id, data),
      ));
      if (entities) {
        this.createOrUpdateAction(entities);
      }
    } catch (error) {
      console.warn('[DEBUG] error updating action', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });
}
