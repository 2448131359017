import { useNavigation } from '@react-navigation/native';
import { Text, useTheme } from '@ui-kitten/components';
import { differenceInMonths, parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { FlatList, Pressable, View } from 'react-native';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import { AppStackNavigation } from '../../navigation';
import { useStore } from '../../stores';
import {
  assessmentFormScheduleData,
  assessmentScheduleData,
  categoryScheduleData,
} from '../../types';

type Props = {
  category: categoryScheduleData;
  rowWidth: number;
  site: string;
  searchId: string;
};

const DrumbeatItem: React.FC<Props> = ({
  category,
  rowWidth,
  site,
  searchId,
}) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const navigation = useNavigation<AppStackNavigation>();

  const store = useStore();
  const { userStore, isInternetReachable } = store;

  const renderAssessmentForms = useCallback(
    ({ item }: { item: assessmentFormScheduleData }) => {
      const monthsToDelete: number[] = [];
      let months = item.months;
      item.months.forEach((sched) => {
        if (sched.duration && sched.duration > 1) {
          const m: number[] = [];
          for (let i = 1; i < sched.duration; i++) {
            m.push(sched.month + i);
          }
          monthsToDelete.push(...m);
        }
      });
      if (monthsToDelete.length) {
        months = item.months.filter(
          (sched) => !monthsToDelete.includes(sched.month),
        );
      }

      return (
        <FlatList
          data={months}
          renderItem={renderAssessmentSchedules}
          horizontal
          extraData={[rowWidth, isInternetReachable]}
          ListHeaderComponent={() => (
            <View style={[styles.drumbeatItem, { width: rowWidth * 0.32 }]}>
              <Text
                style={[
                  styles.drumbeatText,
                  {
                    marginHorizontal: 16,
                    textAlign: 'left',
                    fontSize: 14,
                  },
                ]}
              >
                {item.assessmentForm}
              </Text>
            </View>
          )}
        />
      );
    },
    [rowWidth, searchId, isInternetReachable],
  );

  const renderAssessmentSchedules = useCallback(
    ({ item, index }: { item: assessmentScheduleData; index: number }) => {
      let scheduleDate: Date;
      let isNotLate = false;
      let user: any;
      let migratedUser: any;
      let widthFactor = 1;
      if (item.dateScheduled) {
        scheduleDate = parseISO(item.dateScheduled);
        isNotLate =
          item.duration && item.duration > 1
            ? differenceInMonths(
                new Date(),
                scheduleDate.setMonth(scheduleDate.getMonth() + item.duration),
              ) < 1
            : differenceInMonths(new Date(), scheduleDate) < 2;

        user = item.assignedAssessor
          ? userStore.getUser(item.assignedAssessor)
          : undefined;
        migratedUser = item.migratedAssessor;
        widthFactor =
          item.duration && item.duration > 1
            ? index + item.duration > 12
              ? item.duration - (index + item.duration - 12)
              : item.duration
            : 1;
      }
      return (
        <>
          {item.dateScheduled ? (
            <Pressable
              style={[
                styles.drumbeatCell,
                !item.assessment
                  ? isNotLate
                    ? item.duration
                      ? styles.cellEventDriven
                      : styles.cellScheduled
                    : styles.cellOverdue
                  : styles.cellCompleted,
                { width: ((rowWidth * 0.68) / 12) * widthFactor },
              ]}
              disabled={!isInternetReachable}
              onPress={() => {
                navigation.navigate('Drumbeat Modal', {
                  assessmentScheduleData: item,
                  categoryTitle: category.title,
                });
              }}
            >
              <Text
                style={[
                  styles.drumbeatText,
                  !item.assessment
                    ? isNotLate
                      ? item.duration
                        ? { color: theme['text-white'] }
                        : user || migratedUser
                        ? { color: theme['blue-03'] }
                        : { color: theme['badge-blue'] }
                      : { color: theme['text-white'] }
                    : { color: theme['text-white'] },
                ]}
              >
                {user
                  ? `${user.firstName.toUpperCase()} ${user.lastName.toUpperCase()}`
                  : migratedUser
                  ? migratedUser.toUpperCase()
                  : isNotLate
                  ? item.duration
                    ? 'EVENT-DRIVEN'
                    : 'SCHEDULED'
                  : 'OVERDUE'}
              </Text>
            </Pressable>
          ) : (
            <View
              style={[styles.drumbeatCell, { width: (rowWidth * 0.68) / 12 }]}
            >
              <Text>&nbsp;</Text>
            </View>
          )}
        </>
      );
    },
    [rowWidth, searchId, isInternetReachable],
  );

  const renderSeparator = useCallback(
    () => <View style={styles.separator} />,
    [],
  );

  return (
    <FlatList
      data={category.data}
      renderItem={renderAssessmentForms}
      ItemSeparatorComponent={renderSeparator}
      ListHeaderComponent={() => (
        <View style={styles.drumbeatCategory}>
          <Text
            style={[
              styles.drumbeatText,
              { marginLeft: 16, textAlign: 'left', fontSize: 14 },
            ]}
          >
            {category.title}
          </Text>
        </View>
      )}
    />
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    drumbeatItem: {
      flexDirection: 'row',
      paddingVertical: 10,
      alignItems: 'center',
      backgroundColor: 'text-white',
      minHeight: 40,
    },
    drumbeatCategory: {
      flex: 1,
      flexDirection: 'row',
      paddingVertical: 10,
      backgroundColor: 'grey-200',
    },
    drumbeatCell: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderLeftWidth: 1,
      borderLeftColor: 'gray-06',
      backgroundColor: 'text-white',
      minHeight: 40,
    },
    drumbeatText: {
      fontSize: 10,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      color: 'text-dark',
      textAlign: 'center',
    },
    separator: {
      backgroundColor: 'gray-06',
      height: 1,
    },
    cellCompleted: {
      backgroundColor: 'bp-green',
    },
    cellOverdue: {
      backgroundColor: 'badge-red',
    },
    cellScheduled: {
      backgroundColor: 'blue-02',
    },
    cellEventDriven: {
      backgroundColor: 'badge-yellow',
    },
  },
});

export default observer(DrumbeatItem);
