import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  objectMap,
  getRoot,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import Store from './Store';
import PerformanceUnit from '../models/PerformanceUnit';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('bpEwells/PerformanceUnitStore')
export default class PerformanceUnitStore extends Model({
  performanceUnits: prop(() => objectMap<PerformanceUnit>()),
}) {
  @observable
  loading = false;

  getPerformanceUnit = (id: number): PerformanceUnit | undefined => {
    return this.performanceUnits.get(`${id}`);
  };

  getPerformanceUnits = (): PerformanceUnit[] => {
    return Array.from(this.performanceUnits.values());
  };

  getActivePerformanceUnits = (): PerformanceUnit[] => {
    return Array.from(this.performanceUnits.values()).filter((p) => p.isActive);
  };

  @modelAction
  createOrUpdatePerformanceUnit(data: ModelCreationData<PerformanceUnit>) {
    const id = `${data.id}`;

    let performanceUnit: PerformanceUnit;
    if (this.performanceUnits.has(id)) {
      performanceUnit = this.performanceUnits.get(id)!;
    } else {
      performanceUnit = new PerformanceUnit(data);
      this.performanceUnits.set(id, performanceUnit);
    }

    performanceUnit.update(data);
  }

  @modelFlow
  fetchPerformanceUnits = _async(function* (this: PerformanceUnitStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<PerformanceUnit>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(
        api.fetchPerformanceUnits(rootStore.authStore.accessToken),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching performance units', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdatePerformanceUnit(data));

    this.loading = false;
    return getSuccess();
  });
}
