import {
  useStyleSheet,
  StyleService,
  Text,
  Icon,
  useTheme,
} from '@ui-kitten/components';
import React, { useEffect } from 'react';
import Animated, {
  useSharedValue,
  useAnimatedStyle,
  withTiming,
  runOnJS,
} from 'react-native-reanimated';

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  message: string;
  success?: boolean;
  error?: boolean;
  toastDuration?: number;
};

const Toast: React.FC<Props> = ({
  visible,
  setVisible,
  message,
  success = false,
  error = false,
  toastDuration = 1500,
}) => {
  const styles = useStyleSheet(themedStyles);
  const theme = useTheme();
  const opacity = useSharedValue(0);

  useEffect(() => {
    if (visible) {
      opacity.value = withTiming(1, { duration: 500 });

      if (toastDuration > 0) {
        setTimeout(() => {
          opacity.value = withTiming(
            0,
            { duration: 400 },
            (finished) => finished && runOnJS(setVisible)(false),
          );
        }, toastDuration);
      }
    } else {
      opacity.value = withTiming(0, { duration: 400 });
    }
  }, [visible, toastDuration]);

  const animatedStyle = useAnimatedStyle(() => ({
    opacity: opacity.value,
  }));

  if (!visible) return null;

  return (
    <Animated.View style={[styles.container, { bottom: 40 }, animatedStyle]}>
      {success && (
        <Icon
          name="checkmark-circle-2"
          width={28}
          height={28}
          fill={theme['bp-green']}
        />
      )}
      {error && (
        <Icon
          name="close-circle"
          width={28}
          height={28}
          fill={theme['badge-red']}
        />
      )}
      <Text style={styles.message}>{message}</Text>
    </Animated.View>
  );
};

const themedStyles = StyleService.create({
  container: {
    position: 'absolute',
    backgroundColor: 'input-field-background',
    borderWidth: 1,
    borderColor: 'input-field-outline',
    paddingHorizontal: 16,
    paddingVertical: 20,
    borderRadius: 4,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
  },
  message: {
    marginLeft: 16,
    fontSize: 16,
    fontFamily: 'UniversBP_Light',
    fontWeight: 'bold',
  },
});

export default Toast;
