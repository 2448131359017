import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  objectMap,
  getRoot,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import Store from './Store';
import User from '../models/User';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('bpEwells/UserStore')
export default class UserStore extends Model({
  users: prop(() => objectMap<User>()),
}) {
  @observable
  loading = false;

  getUser = (id: number): User | undefined => {
    return Array.from(this.users.values()).find((user) => user.id === id);
  };

  getUsers = ({
    performanceUnitId,
    siteId,
  }: {
    performanceUnitId?: number;
    siteId?: number;
  }): User[] => {
    let data: User[] = Array.from(this.users.values());
    if (performanceUnitId && siteId) {
      data = Array.from(this.users.values()).filter(
        (u) =>
          u.performanceUnit === performanceUnitId && u.sites.includes(siteId),
      );
    } else if (siteId) {
      data = Array.from(this.users.values()).filter((u) =>
        u.sites.includes(siteId),
      );
    }
    return data.filter((user) => user.isActive);
  };

  @modelAction
  createOrUpdateUser(data: ModelCreationData<User>) {
    const id = `${data.id}`;

    let user: User;
    if (this.users.has(id)) {
      user = this.users.get(id)!;
    } else {
      user = new User(data);
      this.users.set(id, user);
    }

    user.update(data);
  }

  @modelFlow
  fetchUsers = _async(function* (this: UserStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<User>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchUsers(rootStore.authStore.accessToken)));
    } catch (error) {
      console.warn('[DEBUG] error fetching users', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    results.forEach((user) => {
      this.createOrUpdateUser(user);
      if (
        rootStore.authStore &&
        rootStore.authStore.user &&
        user.id === rootStore.authStore.user.id
      ) {
        rootStore.authStore.updateUser(user);
      }
    });

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  setPerformanceUnitSite = _async(function* (
    this: UserStore,
    user: number,
    performanceUnit: number,
    site: number,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let entities: any;
    try {
      ({
        response: { entities },
      } = yield* _await(
        api.setPerformanceUnitSite(
          rootStore.authStore.accessToken,
          user,
          performanceUnit,
          site,
        ),
      ));
      if (entities) {
        this.createOrUpdateUser(entities);
        rootStore.authStore.updateUser(entities);
      }
    } catch (error) {
      console.warn('[DEBUG] error submitting user details', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    this.loading = false;
    return getSuccess();
  });
}
