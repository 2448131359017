import { Icon, Text } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { View, Pressable } from 'react-native';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';

type Props = {
  title?: string;
  leftFooterText?: string;
  rightFooterText?: string;
  onLeftPressFooterText?: () => void;
  onRightPressFooterText?: () => void;
  children?: any;
};

const GraphCard: React.FC<Props> = ({
  title,
  leftFooterText,
  rightFooterText,
  onLeftPressFooterText,
  onRightPressFooterText,
  children,
}) => {
  const styles = useResponsiveStyleSheet(themedStyles);

  return (
    <View style={styles.graphCard}>
      <View style={styles.graphHeader}>
        {title ? <Text style={styles.graphHeaderText}>{title}</Text> : <></>}
      </View>
      <View style={styles.container}>{children}</View>
      <View style={styles.graphFooter}>
        {leftFooterText ? (
          <Pressable
            style={{ flexDirection: 'row' }}
            onPress={onLeftPressFooterText}
          >
            <Text style={[styles.graphFooterText, { marginRight: 11 }]}>
              {leftFooterText}
            </Text>
            <Icon name="arrow-forward-outline" width={17} height={17} />
          </Pressable>
        ) : (
          <></>
        )}
        {rightFooterText ? (
          <Pressable onPress={onRightPressFooterText}>
            <Text style={styles.graphFooterText}>{rightFooterText}</Text>
          </Pressable>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
      flexDirection: 'row',
      alignContent: 'flex-start',
      justifyContent: 'flex-start',
      margin: 0,
      paddingHorizontal: 10,
    },
    graphCard: {
      flex: 0.325,
      aspectRatio: 428 / 277,
      borderRadius: 16,
      backgroundColor: 'text-white',
    },
    graphHeader: {
      paddingBottom: 0,
      paddingHorizontal: 20,
      paddingTop: 16,
      height: 39,
    },
    graphHeaderText: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    graphFooter: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingVertical: 14,
      paddingHorizontal: 20,
      height: 46,
      backgroundColor: 'gray-08',
      borderBottomRightRadius: 16,
      borderBottomLeftRadius: 16,
    },
    graphFooterText: {
      fontFamily: 'UniversBP_Regular',
      fontSize: 14,
      color: 'text-dark',
    },
  },
});

export default observer(GraphCard);
