import { callApiWithToken } from './base';
import config from '../../config';

export const fetchAssessmentSchedules = async (token: string) =>
  callApiWithToken(`${config.urls.assessmentSchedules}`, token, 'GET');

export const fetchAssessmentSchedulesSite = async (
  token: string,
  site: number | undefined,
  year: number | undefined,
) =>
  callApiWithToken(
    `${config.urls.assessmentSchedulesSite}?site=${site}&year=${year}`,
    token,
    'GET',
  );

export const assignAssessor = async (
  token: string,
  id: string,
  assessor: number | undefined,
) => {
  return callApiWithToken(
    `${config.urls.assessmentSchedules}${id}/assign-assessor/`,
    token,
    'POST',
    { 'Content-Type': 'application/json' },
    JSON.stringify({ assigned_assessor: assessor }),
  );
};

export const setNotApplicable = async (token: string, id: string) =>
  callApiWithToken(
    `${config.urls.assessmentSchedules}${id}/`,
    token,
    'PATCH',
    { 'Content-Type': 'application/json' },
    JSON.stringify({ is_applicable: false }),
  );
