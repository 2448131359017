import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  objectMap,
  getRoot,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import Store from './Store';
import Action from '../models/Action';
import Answer from '../models/Answer';
import Assessment from '../models/Assessment';
import AssessmentForm from '../models/AssessmentForm';
import AssessmentSchedule from '../models/AssessmentSchedule';
import Attachment from '../models/Attachment';
import CatAction from '../models/CatAction';
import FormSection from '../models/FormSection';
import HistoricalAssessmentForm from '../models/HistoricalAssessmentForm';
import HistoricalFormSection from '../models/HistoricalFormSection';
import HistoricalQuestion from '../models/HistoricalQuestion';
import Question from '../models/Question';
import * as api from '../services/api';
import {
  ActionStatistics,
  ActionItemEntryData,
  AnswerData,
  AssessmentData,
  AttachmentDeleteForm,
  FilterData,
  GapStatistics,
  ScheduleStatistics,
} from '../types';
import { getError, getSuccess } from '../utils/models';

@model('bpEwells/AssessmentStore')
export default class AssessmentStore extends Model({
  assessments: prop(() => objectMap<Assessment>()),
  answers: prop(() => objectMap<Answer>()),
  attachments: prop(() => objectMap<Attachment>()),
}) {
  @observable
  loading = false;

  getAssessment = (id: number): Assessment | undefined => {
    return this.assessments.get(`${id}`);
  };

  getAssessments = (): Assessment[] => {
    return Array.from(this.assessments.values());
  };

  getAnswer = (id: number): Answer | undefined => {
    return this.answers.get(`${id}`);
  };

  getAnswers = (): Answer[] => {
    return Array.from(this.answers.values());
  };

  getActionItems = (): Answer[] => {
    return Array.from(this.answers.values()).filter(
      (ans) => ans.answer === 'No',
    );
  };

  getAttachment = (id: number): Attachment | undefined => {
    return this.attachments.get(`${id}`);
  };

  getAttachments = (): Attachment[] => {
    return Array.from(this.attachments.values());
  };

  @modelAction
  createOrUpdateAssessment(data: ModelCreationData<Assessment>) {
    const id = `${data.id}`;

    let assessment: Assessment;
    if (this.assessments.has(id)) {
      assessment = this.assessments.get(id)!;
    } else {
      assessment = new Assessment(data);
      this.assessments.set(id, assessment);
    }

    assessment.update(data);
  }

  @modelAction
  createOrUpdateAnswer(data: ModelCreationData<Answer>) {
    const id = `${data.id}`;

    let answer: Answer;
    if (this.answers.has(id)) {
      answer = this.answers.get(id)!;
    } else {
      answer = new Answer(data);
      this.answers.set(id, answer);
    }

    answer.update(data);
  }

  @modelAction
  createOrUpdateAttachment(data: ModelCreationData<Attachment>) {
    const id = `${data.id}`;

    let attachment: Attachment;
    if (this.attachments.has(id)) {
      attachment = this.attachments.get(id)!;
    } else {
      attachment = new Attachment(data);
      this.attachments.set(id, attachment);
    }

    attachment.update(data);
  }

  @modelAction
  deleteAssessmentModel(id: number) {
    const assessmentID = `${id}`;
    if (this.assessments.has(assessmentID)) {
      this.assessments.delete(assessmentID);
    }
  }

  @modelAction
  deleteAttachment(id: number) {
    const attachmentId = `${id}`;
    if (this.attachments.has(attachmentId)) {
      this.attachments.delete(attachmentId);
    }
  }

  @modelFlow
  fetchAssessments = _async(function* (
    this: AssessmentStore,
    filters: FilterData,
    pageNum: number,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let next: string;
    let count: string;
    let results: ModelCreationData<Assessment>[];
    try {
      ({
        response: {
          entities: { count, next, results },
        },
      } = yield* _await(
        api.fetchAssessments(rootStore.authStore.accessToken, filters, pageNum),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching assessments', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateAssessment(data));

    this.loading = false;
    return getSuccess({ next, results, count });
  });

  @modelFlow
  fetchUserDraftAssessments = _async(function* (this: AssessmentStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let assessments: ModelCreationData<Assessment>[];
    let answers: ModelCreationData<Answer>[];
    let attachments: ModelCreationData<Attachment>[];
    let assessmentForms: ModelCreationData<AssessmentForm>[];
    let questions: ModelCreationData<Question>[];
    let formSections: ModelCreationData<FormSection>[];

    try {
      ({
        response: {
          entities: {
            assessments,
            answers,
            attachments,
            assessmentForms,
            questions,
            formSections,
          },
        },
      } = yield* _await(
        api.fetchUserDraftAssessments(rootStore.authStore.accessToken),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching user draft assessments', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    assessments.forEach((data) => this.createOrUpdateAssessment(data));
    answers.forEach((data) => this.createOrUpdateAnswer(data));
    attachments.forEach((data) => this.createOrUpdateAttachment(data));
    assessmentForms.forEach((data) =>
      rootStore.assessmentFormStore.createOrUpdateAssessmentForm(data),
    );
    questions.forEach((data) =>
      rootStore.assessmentFormStore.createOrUpdateQuestion(data),
    );
    formSections.forEach((data) =>
      rootStore.assessmentFormStore.createOrUpdateFormSection(data),
    );

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchAssessmentDetails = _async(function* (
    this: AssessmentStore,
    id: number,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let assessment: ModelCreationData<Assessment>;
    let answers: ModelCreationData<Answer>[];
    let attachments: ModelCreationData<Attachment>[];
    let assessmentForm: ModelCreationData<AssessmentForm>;
    let questions: ModelCreationData<Question>[];
    let formSections: ModelCreationData<FormSection>[];

    try {
      ({
        response: {
          entities: {
            assessment,
            answers,
            attachments,
            assessmentForm,
            questions,
            formSections,
          },
        },
      } = yield* _await(
        api.fetchAssessmentDetails(rootStore.authStore.accessToken, id),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching assessment details', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    this.createOrUpdateAssessment(assessment);
    answers.forEach((data) => this.createOrUpdateAnswer(data));
    attachments.forEach((data) => this.createOrUpdateAttachment(data));
    rootStore.assessmentFormStore.createOrUpdateAssessmentForm(assessmentForm);
    questions.forEach((data) =>
      rootStore.assessmentFormStore.createOrUpdateQuestion(data),
    );
    formSections.forEach((data) =>
      rootStore.assessmentFormStore.createOrUpdateFormSection(data),
    );

    this.loading = false;
    return getSuccess({
      assessmentForm: assessment.assessmentForm,
      modified: assessment.modified,
    });
  });

  @modelFlow
  fetchAssessmentHistoricalDetails = _async(function* (
    this: AssessmentStore,
    id: number,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let assessment: ModelCreationData<Assessment>;
    let answers: ModelCreationData<Answer>[];
    let attachments: ModelCreationData<Attachment>[];
    let historicalAssessmentForm: ModelCreationData<HistoricalAssessmentForm>;
    let historicalFormSections: ModelCreationData<HistoricalFormSection>[];
    let historicalQuestions: ModelCreationData<HistoricalQuestion>[];

    try {
      ({
        response: {
          entities: {
            assessment,
            answers,
            attachments,
            historicalAssessmentForm,
            historicalFormSections,
            historicalQuestions,
          },
        },
      } = yield* _await(
        api.fetchAssessmentHistoricalDetails(
          rootStore.authStore.accessToken,
          id,
        ),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching assessment details', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    this.createOrUpdateAssessment(assessment);
    answers.forEach((data) => this.createOrUpdateAnswer(data));
    attachments.forEach((data) => this.createOrUpdateAttachment(data));
    rootStore.historicalAssessmentFormStore.createOrUpdateHistoricalAssessmentForm(
      historicalAssessmentForm,
    );
    historicalFormSections.forEach((data) =>
      rootStore.historicalAssessmentFormStore.createOrUpdateHistoricalFormSection(
        data,
      ),
    );
    historicalQuestions.forEach((data) =>
      rootStore.historicalAssessmentFormStore.createOrUpdateHistoricalQuestion(
        data,
      ),
    );

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchAnswers = _async(function* (this: AssessmentStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<Answer>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchAnswers(rootStore.authStore.accessToken)));
    } catch (error) {
      console.warn('[DEBUG] error fetching answers', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateAnswer(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchAttachments = _async(function* (this: AssessmentStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<Attachment>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchAttachments(rootStore.authStore.accessToken)));
    } catch (error) {
      console.warn('[DEBUG] error fetching attachments', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateAttachment(data));

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchStatistics = _async(function* (
    this: AssessmentStore,
    filters: FilterData,
    pageNum: number,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let assessments: ModelCreationData<Assessment>[];
    let gaps: GapStatistics;
    let actions: ActionStatistics;
    let schedules: ScheduleStatistics;
    let next: string;
    let count: string;

    try {
      ({
        response: {
          entities: {
            count,
            next,
            results: {
              assessments,
              statistics: { schedules, gaps, actions },
            },
          },
        },
      } = yield* _await(
        api.fetchStatistics(rootStore.authStore.accessToken, filters, pageNum),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching statistics', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    assessments.forEach((data) => this.createOrUpdateAssessment(data));

    this.loading = false;
    return getSuccess({
      next,
      statistics: { schedules, gaps, actions },
      assessments,
      count,
    });
  });

  @modelFlow
  addAssessment = _async(function* (
    this: AssessmentStore,
    data: AssessmentData,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }
    const deviceInfo = rootStore.userMetricsStore.getDeviceInfo();

    this.loading = true;
    let assessment: ModelCreationData<Assessment>;
    let schedule: ModelCreationData<AssessmentSchedule> | null;
    try {
      ({
        response: {
          entities: { assessment, schedule },
        },
      } = yield* _await(
        api.addAssessment(rootStore.authStore.accessToken, data, deviceInfo),
      ));
    } catch (error) {
      console.warn('[DEBUG] error submitting assessment', error);
      const isTokenRefreshed = yield* _await(
        rootStore.authStore.checkToken(error),
      );
      if (isTokenRefreshed) {
        try {
          ({
            response: {
              entities: { assessment, schedule },
            },
          } = yield* _await(
            api.addAssessment(
              rootStore.authStore.accessToken,
              data,
              deviceInfo,
            ),
          ));
        } catch (retryError) {
          console.warn(
            '[DEBUG] error retrying submitting assessment',
            retryError,
          );
          return getError(retryError);
        }
      } else {
        return getError(error);
      }
    }
    this.createOrUpdateAssessment(assessment);
    if (schedule) {
      rootStore.assessmentScheduleStore.createOrUpdateAssessmentSchedule(
        schedule,
      );
    }

    this.loading = false;
    return getSuccess({ id: assessment.id });
  });

  @modelFlow
  addAnswer = _async(function* (
    this: AssessmentStore,
    data: AnswerData | AnswerData[],
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<Answer> | ModelCreationData<Answer>[];
    try {
      ({
        response: { entities: results },
      } = yield* _await(api.addAnswer(rootStore.authStore.accessToken, data)));
    } catch (error) {
      console.warn('[DEBUG] error submitting answers', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    const answerAndQuestionIds: { [key: number]: number } = {};
    if (Array.isArray(results)) {
      results.forEach((data) => {
        this.createOrUpdateAnswer(data);
        answerAndQuestionIds[data.question] = data.id;
      });
    } else {
      this.createOrUpdateAnswer(results);
      answerAndQuestionIds[results.question] = results.id;
    }

    this.loading = false;
    return getSuccess({ ids: answerAndQuestionIds });
  });

  @modelFlow
  addAttachments = _async(function* (
    this: AssessmentStore,
    attachments: any[],
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }
    this.loading = true;

    let results: ModelCreationData<Attachment>[];
    try {
      ({
        response: { entities: results },
      } = yield* _await(
        api.addAttachments(rootStore.authStore.accessToken, attachments),
      ));
    } catch (error) {
      console.warn('[DEBUG] error submitting attachments', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    if (Array.isArray(results)) {
      results.forEach((data) => {
        this.createOrUpdateAttachment(data);
      });
    } else {
      this.createOrUpdateAttachment(results);
    }

    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  updateAssessment = _async(function* (
    this: AssessmentStore,
    data: AssessmentData,
    id: number,
    autosave: boolean = false,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }
    const deviceInfo = rootStore.userMetricsStore.getDeviceInfo();

    this.loading = true;

    let assessment: ModelCreationData<Assessment>;
    let schedule: ModelCreationData<AssessmentSchedule> | null;
    try {
      ({
        response: {
          entities: { assessment, schedule },
        },
      } = yield* _await(
        api.updateAssessment(
          rootStore.authStore.accessToken,
          data,
          deviceInfo,
          autosave,
          id,
        ),
      ));
    } catch (error) {
      console.warn('[DEBUG] error updating assessment', error);
      const isTokenRefreshed = yield* _await(
        rootStore.authStore.checkToken(error),
      );
      if (isTokenRefreshed) {
        try {
          ({
            response: {
              entities: { assessment, schedule },
            },
          } = yield* _await(
            api.updateAssessment(
              rootStore.authStore.accessToken,
              data,
              deviceInfo,
              autosave,
              id,
            ),
          ));
        } catch (retryError) {
          console.warn(
            '[DEBUG] error retrying updating assessment',
            retryError,
          );
          return getError(retryError);
        }
      } else {
        return getError(error);
      }
    }
    this.createOrUpdateAssessment(assessment);
    if (schedule) {
      rootStore.assessmentScheduleStore.createOrUpdateAssessmentSchedule(
        schedule,
      );
    }

    this.loading = false;
    return getSuccess({ id: assessment.id });
  });

  @modelFlow
  updateAnswer = _async(function* (
    this: AssessmentStore,
    data: AnswerData | AnswerData[],
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<Answer> | ModelCreationData<Answer>[];
    try {
      ({
        response: { entities: results },
      } = yield* _await(
        api.updateAnswer(rootStore.authStore.accessToken, data),
      ));
    } catch (error) {
      console.warn('[DEBUG] error updating answers', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    const answerAndQuestionIds: { [key: number]: number } = {};
    if (Array.isArray(results)) {
      results.forEach((data) => {
        this.createOrUpdateAnswer(data);
        answerAndQuestionIds[data.question] = data.id;
      });
    } else {
      this.createOrUpdateAnswer(results);
      answerAndQuestionIds[results.question] = results.id;
    }

    this.loading = false;
    return getSuccess({ ids: answerAndQuestionIds });
  });

  @modelFlow
  deleteAssessment = _async(function* (this: AssessmentStore, id: number) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    try {
      yield* _await(api.deleteAssessment(rootStore.authStore.accessToken, id));
    } catch (error) {
      console.warn('[DEBUG] error deleting assessment', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    this.deleteAssessmentModel(id);
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  deleteAttachments = _async(function* (
    this: AssessmentStore,
    data: AttachmentDeleteForm[],
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: number[];
    try {
      ({
        response: { entities: results },
      } = yield* _await(
        api.deleteAttachments(rootStore.authStore.accessToken, data),
      ));
    } catch (error) {
      console.warn('[DEBUG] error deleting attachments', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    results.forEach((attachmentId) => this.deleteAttachment(attachmentId));
    this.loading = false;
    return getSuccess();
  });

  @modelFlow
  fetchActionItems = _async(function* (
    this: AssessmentStore,
    filters: FilterData,
    pageNum: number,
  ) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let next: string;
    let count: string;
    let answers: ActionItemEntryData[];
    let actions: ModelCreationData<Action>[];
    let catActions: ModelCreationData<CatAction>[];
    try {
      ({
        response: {
          entities: {
            count,
            next,
            results: { answers, actions, catActions },
          },
        },
      } = yield* _await(
        api.fetchActionItems(rootStore.authStore.accessToken, filters, pageNum),
      ));
    } catch (error) {
      console.warn('[DEBUG] error fetching action items', error);
      return getError(error);
    }

    answers.forEach((answer) => this.createOrUpdateAnswer(answer));
    actions.forEach((action) =>
      rootStore.actionStore.createOrUpdateAction(action),
    );
    catActions.forEach((catAction) =>
      rootStore.actionStore.createOrUpdateCatAction(catAction),
    );
    this.loading = false;
    return getSuccess({ answers, next, count });
  });
}
