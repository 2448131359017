import { Icon, Text, useTheme } from '@ui-kitten/components';
import { format, parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Animated, Easing, View, Pressable } from 'react-native';

import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import Assessment from '../../models/Assessment';
import { useStore } from '../../stores';
import { getAssessmentIdFormat } from '../../utils/helper';

type Props = {
  onGoBack: () => void;
  questionIds: number[];
  assessment: Assessment;
};

const ViewAssessmentFormHeader: React.FC<Props> = ({
  onGoBack,
  assessment,
  questionIds,
}) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const store = useStore();
  const {
    assessmentStore,
    performanceUnitStore,
    selfVerificationTypeStore,
    historicalAssessmentFormStore,
    siteStore,
    categoryStore,
    userStore,
  } = store;
  const [showDetails, setShowDetails] = useState(false);

  const site = useMemo(() => {
    return siteStore.getSite(assessment.site);
  }, [assessment, siteStore.sites.values()]);

  const performanceUnit = useMemo(() => {
    return performanceUnitStore.getPerformanceUnit(assessment.performanceUnit);
  }, [assessment, performanceUnitStore.performanceUnits.values()]);

  const assessor = useMemo(() => {
    return userStore.getUser(assessment.assessor);
  }, [assessment, userStore.users.values()]);

  const migratedAssessor = assessment.migratedAssessor;

  const assessmentForm = useMemo(() => {
    return historicalAssessmentFormStore.getAssessmentForm(
      Number(assessment.assessmentFormVersionId),
    );
  }, [
    assessment,
    historicalAssessmentFormStore.historicalAssessmentForms.values(),
  ]);

  const category = useMemo(() => {
    return categoryStore.getCategory(assessment.category);
  }, [assessment, categoryStore.categories.values()]);

  const selfVerificationType = useMemo(() => {
    return selfVerificationTypeStore.getSelfVerificationType(
      assessment.selfVerificationType,
    );
  }, [assessment, selfVerificationTypeStore.selfVerificationTypes.values()]);

  const dateObserved = parseISO(assessment.dateObserved);
  const assessmentId = assessment.id;

  const spinValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(spinValue, {
      duration: 200,
      easing: Easing.linear,
      toValue: showDetails ? 1 : 0,
      useNativeDriver: true,
    }).start();
  }, [showDetails]);

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-180deg'],
  });

  const answerKeys = [];
  for (let i = 0; i < questionIds.length; i++) {
    answerKeys.push(`answers.${i}.answer`);
  }

  const viewAnsweredQuestions = useMemo(() => {
    return assessmentStore
      .getAnswers()
      .filter(
        (answer) =>
          answer.assessment === assessmentId &&
          !!answer.answer &&
          questionIds.includes(answer.question),
      );
  }, [assessment, assessmentStore.answers.values()]);

  let noAnswerCounter = 0;
  let naAnswerCounter = 0;
  let yesAnswerCounter = 0;
  for (let i = 0; i < viewAnsweredQuestions.length; i++) {
    if (viewAnsweredQuestions[i].answer === 'No') {
      noAnswerCounter = noAnswerCounter + 1;
    } else if (viewAnsweredQuestions[i].answer === 'N/A') {
      naAnswerCounter = naAnswerCounter + 1;
    } else if (viewAnsweredQuestions[i].answer === 'Yes') {
      yesAnswerCounter = yesAnswerCounter + 1;
    }
  }

  if (!assessmentForm) {
    return <></>;
  }

  return (
    <>
      <Pressable onPress={() => setShowDetails((previous) => !previous)}>
        <View style={styles.header}>
          <View style={styles.headerTitle}>
            <Text style={[styles.title, { paddingRight: 46 }]}>
              {assessmentId ? (
                <Text style={[styles.title, { color: theme['text-grey'] }]}>
                  AS-{getAssessmentIdFormat(assessmentId.toString())}{' '}
                </Text>
              ) : (
                <></>
              )}
              {assessmentForm.name}
            </Text>

            <Animated.View
              style={{
                height: 32,
                width: 32,
                transform: [{ rotate: spin }],
              }}
            >
              <Icon
                name="chevron-down-outline"
                width={32}
                height={32}
                fill={theme['text-dark']}
              />
            </Animated.View>
          </View>
          <View style={styles.headerSubTitle}>
            <Text style={[styles.title, { fontSize: 16 }]}>
              {assessmentForm && assessmentForm.version
                ? `Version ${assessmentForm.version}  |  Last updated: ${format(
                    parseISO(assessmentForm.modified),
                    'dd MMMM yyyy',
                  )}`
                : assessmentForm && assessmentForm.modified
                ? `Last updated: ${format(
                    parseISO(assessmentForm.modified),
                    'dd MMMM yyyy',
                  )}`
                : ''}
            </Text>
            <View style={styles.counterBadge}>
              <Text
                style={[
                  styles.title,
                  {
                    fontSize: 16,
                    fontWeight: 'normal',
                    color: theme['text-white'],
                  },
                ]}
              >
                {viewAnsweredQuestions.length}/{questionIds.length}
              </Text>
            </View>
            {assessmentId && (
              <>
                <View
                  style={[
                    styles.counterBadge,
                    {
                      backgroundColor: theme['button-success-outline-active'],
                    },
                  ]}
                >
                  <Text
                    style={[
                      styles.title,
                      {
                        fontSize: 16,
                        fontWeight: 'normal',
                        color: theme['text-white'],
                      },
                    ]}
                  >
                    Yes ({yesAnswerCounter})
                  </Text>
                </View>
                <View
                  style={[
                    styles.counterBadge,
                    { backgroundColor: theme['badge-red'] },
                  ]}
                >
                  <Text
                    style={[
                      styles.title,
                      {
                        fontSize: 16,
                        fontWeight: 'normal',
                        color: theme['text-white'],
                      },
                    ]}
                  >
                    No ({noAnswerCounter})
                  </Text>
                </View>
                <View
                  style={[
                    styles.counterBadge,
                    { backgroundColor: theme['badge-blue'] },
                  ]}
                >
                  <Text
                    style={[
                      styles.title,
                      {
                        fontSize: 16,
                        fontWeight: 'normal',
                        color: theme['text-white'],
                      },
                    ]}
                  >
                    NA ({naAnswerCounter})
                  </Text>
                </View>
              </>
            )}
          </View>
        </View>
      </Pressable>
      {showDetails && (
        <>
          <View style={styles.detailsContainer}>
            <View style={styles.detailsLeft}>
              <View style={styles.infoRow}>
                <Text style={styles.infoTitle}>Assessment Id</Text>
                {assessmentId && (
                  <Text style={styles.infoContent}>
                    AS-{getAssessmentIdFormat(assessmentId.toString())}
                  </Text>
                )}
              </View>
              <View style={styles.infoRow}>
                <Text style={styles.infoTitle}>Performance Unit</Text>
                {performanceUnit && (
                  <Text style={styles.infoContent}>{performanceUnit.name}</Text>
                )}
              </View>
              <View style={styles.infoRow}>
                <Text style={styles.infoTitle}>Site</Text>
                {site && <Text style={styles.infoContent}>{site.name}</Text>}
              </View>
              <View style={styles.infoRow}>
                <Text style={styles.infoTitle}>Category</Text>
                {category && (
                  <Text style={styles.infoContent}>{category.name}</Text>
                )}
              </View>
            </View>
            <View style={styles.detailsRight}>
              <View style={styles.infoRow}>
                <Text style={styles.infoTitle}>Assessment Form</Text>
                {assessmentForm && (
                  <Text style={styles.infoContent}>{assessmentForm.name}</Text>
                )}
              </View>
              <View style={styles.infoRow}>
                <Text style={styles.infoTitle}>Date Observed</Text>
                {dateObserved && (
                  <Text style={styles.infoContent}>
                    {format(dateObserved, 'dd MMMM yyyy')}
                  </Text>
                )}
              </View>
              <View style={styles.infoRow}>
                <Text style={styles.infoTitle}>Observer</Text>
                <Text style={styles.infoContent}>
                  {assessor ? assessor.fullName : migratedAssessor}
                </Text>
              </View>
              <View style={styles.infoRow}>
                <Text style={styles.infoTitle}>Self-verification Type</Text>
                {selfVerificationType && (
                  <Text style={styles.infoContent}>
                    {selfVerificationType.name}
                  </Text>
                )}
              </View>
            </View>
          </View>
          {!assessmentId && (
            <Pressable style={styles.editButton} onPress={onGoBack}>
              <Text style={styles.edit}>Edit details</Text>
            </Pressable>
          )}
        </>
      )}
    </>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    header: {
      paddingTop: 24,
      paddingBottom: 17,
      minHeight: 69,
    },
    headerTitle: {
      flexDirection: 'row',
      minWidth: 769,
    },
    title: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 24,
      color: 'text-dark',
    },
    headerSubTitle: {
      flexDirection: 'row',
      paddingTop: 5,
      alignItems: 'center',
    },
    counterBadge: {
      height: 32,
      minWidth: 88,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'badge-grey',
      borderRadius: 16,
      paddingTop: 7,
      paddingBottom: 8,
      marginLeft: 13,
    },
    detailsContainer: {
      marginTop: 20,
      flexDirection: 'row',
    },
    detailsLeft: {
      flexDirection: 'column',
      minWidth: 522,
    },
    detailsRight: {
      flexDirection: 'column',
      minWidth: 686,
    },
    infoRow: {
      flexDirection: 'row',
      alignItems: 'baseline',
      paddingBottom: 24,
    },
    infoTitle: {
      minWidth: 182,
      fontFamily: 'UniversLTPro_Regular',
      fontSize: 14,
      color: 'text-grey',
    },
    infoContent: {
      minWidth: 223,
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    editButton: {
      marginTop: 8,
      marginBottom: 24,
    },
    edit: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'bp-green',
    },
    separator: {
      height: 1,
      backgroundColor: 'input-field-outline',
    },
  },
});

export default observer(ViewAssessmentFormHeader);
