import NetInfo from '@react-native-community/netinfo';
import { HeaderTitle } from '@react-navigation/elements';
import {
  getFocusedRouteNameFromRoute,
  LinkingOptions,
  NavigationContainer,
  NavigatorScreenParams,
  useNavigation,
} from '@react-navigation/native';
import {
  createStackNavigator,
  StackNavigationProp,
} from '@react-navigation/stack';
import { Icon, Text, useTheme } from '@ui-kitten/components';
import { parseISO } from 'date-fns';
import { pick } from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Image, ImageStyle, Pressable, View } from 'react-native';
import Tooltip from 'react-native-walkthrough-tooltip';

import LinkingConfiguration from './LinkingConfiguration';
import CastrolLogo from '../../assets/images/castrol_vector_logo.svg';
import EverifyWhiteLogo from '../../assets/images/everify_logo_green_white.svg';
import CustomTooltip from '../components/Common/CustomTooltip';
import SyncIndicator from '../components/Common/SyncIndicator';
import Toast from '../components/Common/Toast';
import config from '../config';
import AddAssessments from '../containers/AddAssessments';
import CreateAction from '../containers/CreateAction';
import DrumbeatModal from '../containers/DrumbeatModal';
import EditAssessments from '../containers/EditAssessments';
import ViewAssessments from '../containers/ViewAssessments';
import WelcomeModal from '../containers/WelcomeModal';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../hooks/useResponsiveStyleSheet';
import useUserDefaultDetails from '../hooks/useUserDefaultDetails';
import { useStore } from '../stores';
import {
  AnswerData,
  AnswerForm,
  AssessmentData,
  AssessmentFormData,
  assessmentScheduleData,
  AttachmentForm,
  FileItem,
  StoredFormData,
} from '../types';
import { chunkArray, useUserFullName } from '../utils/helper';
import MainDrawer from './drawer/MainDrawer';
import AuthStack, { AuthStackParamList } from './stacks/AuthStack';

export type AppStackParamList = {
  Main: NavigatorScreenParams<MainDrawerParamList>;
  'Add Assessments':
    | {
        modalAssessmentScheduleId?: string;
        modalAssessmentFormId?: string;
        modalAssessmentFormVersionId?: string;
        modalPerformanceUnitId?: string;
        modalSiteId?: string;
        modalCategoryId?: string;
        modalSvtId?: string;
        cameFrom?: string;
      }
    | undefined;
  'View Assessments': { assessmentId: number; cameFrom?: string };
  'Edit Assessments': { draftAssessmentId: number; cameFrom?: string };
  'Drumbeat Modal': {
    assessmentScheduleData: assessmentScheduleData;
    categoryTitle: string;
  };
  'Welcome to eVerify': undefined;
  'Create Action': {
    answerId: string;
    questionId: number;
    actionId?: string;
    assessmentId?: string;
    assessmentForm?: string;
    questionString?: string;
  };
};

export type MainDrawerParamList = {
  Dashboard: { reload?: boolean } | undefined;
  Assessments: undefined;
  Actions: undefined;
};

export type AppStackNavigation = StackNavigationProp<AppStackParamList>;

type Props = object;

const Stack = createStackNavigator<AppStackParamList>();

const getHeaderTitle = (route: any) => {
  const routeName = getFocusedRouteNameFromRoute(route) ?? 'Dashboard';

  switch (routeName) {
    case 'Dashboard':
      return 'Dashboard';
    case 'Assessments':
      return 'Assessments';
    case 'Actions':
      return 'Actions';
  }
};

const AppStack: React.FC = () => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const theme = useTheme();
  const navigation = useNavigation<any>();
  const userFullName = useUserFullName();
  const [showTip, setShowTip] = useState<boolean>(false);
  const [isLogoutHovered, setIsLogoutHovered] = useState<boolean>(false);

  const store = useStore();
  const { authStore } = store;

  const photoURI = `${authStore.user?.photoSizes.big}`;

  const onLogout = () => {
    authStore.logOut();
  };

  return (
    <Stack.Navigator
      screenOptions={{
        animationEnabled: false,
        headerShown: true,
        headerStyle: {
          backgroundColor: theme['green-06'],
        },
        headerTitleStyle: styles.headerTitle,
        headerTintColor: theme['text-white'],
        headerLeft: () => (
          <View style={styles.headerLeftContainer}>
            <Pressable onPress={() => navigation.navigate('Dashboard')}>
              <View style={styles.logoContainer}>
                <View style={{ paddingTop: 3 }}>
                  <CastrolLogo width={97} height={23} />
                </View>
                <View style={styles.logoBorder} />
                <EverifyWhiteLogo height={30} />
              </View>
            </Pressable>
          </View>
        ),
      }}
    >
      <Stack.Screen
        name="Main"
        component={MainDrawer}
        options={({ route }) => ({
          headerTitle: getHeaderTitle(route),
          headerRight: () => (
            <View style={styles.headerRightContainer}>
              <CustomTooltip
                showTip={showTip}
                contentText="This is your offline indicator. If it is gray, you are
                  offline, and your inputs will be temporarily stored until you
                  are reconnected to the internet"
                setShowTip={setShowTip}
                placement="bottom"
                multiline
              >
                <Pressable
                  onLongPress={() => setShowTip(true)}
                  // @ts-ignore
                  onMouseEnter={() => setShowTip(true)}
                  // @ts-ignore
                  onMouseLeave={() => setShowTip(false)}
                >
                  <SyncIndicator />
                </Pressable>
              </CustomTooltip>
              <View style={styles.nameContainer}>
                {userFullName && (
                  <Text style={styles.fullName}>
                    {userFullName}
                    {'  '}- Field Inspector
                  </Text>
                )}

                <Text style={styles.notYou}>
                  Not you?{' '}
                  <Pressable
                    onPress={onLogout}
                    // @ts-ignore
                    onMouseEnter={() => setIsLogoutHovered(true)}
                    // @ts-ignore
                    onMouseLeave={() => setIsLogoutHovered(false)}
                  >
                    <Text
                      style={
                        isLogoutHovered
                          ? [styles.logOut, { textDecorationLine: 'underline' }]
                          : styles.logOut
                      }
                    >
                      Logout
                    </Text>
                  </Pressable>
                </Text>
              </View>
              {authStore.user?.photoSizes.big ? (
                <Image
                  style={styles.image as ImageStyle}
                  source={{
                    uri: photoURI,
                  }}
                />
              ) : (
                <Icon height={32} fill="#809987" name="stop-circle-outline" />
              )}
            </View>
          ),
        })}
      />
      <Stack.Screen
        name="Add Assessments"
        component={AddAssessments}
        options={() => ({
          cardStyle: { flex: 1 },
          headerTitle: () => (
            <Pressable onPress={() => navigation.goBack()}>
              <HeaderTitle style={styles.headerTitle} tintColor="#ffffff">
                Back to Assessments
              </HeaderTitle>
            </Pressable>
          ),
          headerLeft: () => (
            <Pressable
              onPress={() => navigation.goBack()}
              style={styles.headerLeftContainer}
            >
              <Icon
                name="arrow-back-outline"
                width={32}
                height={32}
                fill="#ffffff"
              />
            </Pressable>
          ),
          headerRight: () => (
            <View style={styles.headerRightContainer}>
              <Tooltip
                isVisible={showTip}
                content={
                  <View>
                    <Text style={{ color: '#FFFFFF' }}>
                      This is your offline indicator. If it is gray, you are
                      offline, and your inputs will be temporarily stored until
                      you are reconnected to the internet
                    </Text>
                  </View>
                }
                onClose={() => setShowTip(false)}
                placement="bottom"
                showChildInTooltip={false}
                backgroundColor="rgba(0,0,0,0)"
                disableShadow
                arrowStyle={{
                  borderTopColor: 'rgba(79, 195, 247, 1)',
                }}
                contentStyle={{
                  backgroundColor: 'rgba(79, 195, 247, 1)',
                }}
              >
                <Pressable onPress={() => setShowTip(true)}>
                  <SyncIndicator />
                </Pressable>
              </Tooltip>
            </View>
          ),
        })}
      />
      <Stack.Screen
        name="View Assessments"
        component={ViewAssessments}
        options={() => ({
          cardStyle: { flex: 1 },
          headerTitle: () => (
            <Pressable onPress={() => navigation.goBack()}>
              <HeaderTitle style={styles.headerTitle} tintColor="#ffffff">
                Back to Assessments
              </HeaderTitle>
            </Pressable>
          ),
          headerLeft: () => (
            <Pressable
              onPress={() => navigation.goBack()}
              style={styles.headerLeftContainer}
            >
              <Icon
                name="arrow-back-outline"
                width={32}
                height={32}
                fill="#ffffff"
              />
            </Pressable>
          ),
        })}
      />
      <Stack.Screen
        name="Edit Assessments"
        component={EditAssessments}
        options={() => ({
          cardStyle: { flex: 1 },
          headerTitle: () => (
            <Pressable onPress={() => navigation.goBack()}>
              <HeaderTitle style={styles.headerTitle} tintColor="#ffffff">
                Back to Assessments
              </HeaderTitle>
            </Pressable>
          ),
          headerLeft: () => (
            <Pressable
              onPress={() => navigation.goBack()}
              style={styles.headerLeftContainer}
            >
              <Icon
                name="arrow-back-outline"
                width={32}
                height={32}
                fill="#ffffff"
              />
            </Pressable>
          ),
        })}
      />
      <Stack.Screen
        name="Drumbeat Modal"
        component={DrumbeatModal}
        options={() => ({
          presentation: 'transparentModal',
          headerShown: false,
          cardOverlayEnabled: true,
        })}
      />
      <Stack.Screen
        name="Welcome to eVerify"
        component={WelcomeModal}
        options={() => ({
          presentation: 'transparentModal',
          headerShown: false,
          cardOverlayEnabled: true,
        })}
      />
      <Stack.Screen
        name="Create Action"
        component={CreateAction}
        options={() => ({
          headerTitle: () => (
            <Pressable
              onPress={() => navigation.navigate('Main', { screen: 'Actions' })}
            >
              <HeaderTitle style={styles.headerTitle} tintColor="#ffffff">
                Back to Action items
              </HeaderTitle>
            </Pressable>
          ),
          headerLeft: () => (
            <Pressable
              onPress={() => navigation.navigate('Main', { screen: 'Actions' })}
              style={styles.headerLeftContainer}
            >
              <Icon
                name="arrow-back-outline"
                width={32}
                height={32}
                fill="#ffffff"
              />
            </Pressable>
          ),
          headerRight: () => (
            <View style={styles.headerRightContainer}>
              <Tooltip
                isVisible={showTip}
                content={
                  <View>
                    <Text style={{ color: '#FFFFFF' }}>
                      This is your offline indicator. If it is gray, you are
                      offline, and your inputs will be temporarily stored until
                      you are reconnected to the internet
                    </Text>
                  </View>
                }
                onClose={() => setShowTip(false)}
                placement="bottom"
                showChildInTooltip={false}
                backgroundColor="rgba(0,0,0,0)"
                disableShadow
                arrowStyle={{
                  borderTopColor: 'rgba(79, 195, 247, 1)',
                }}
                contentStyle={{
                  backgroundColor: 'rgba(79, 195, 247, 1)',
                }}
              >
                <Pressable onPress={() => setShowTip(true)}>
                  <SyncIndicator />
                </Pressable>
              </Tooltip>
            </View>
          ),
        })}
      />
    </Stack.Navigator>
  );
};

const Stacks = {
  AppStack,
  AuthStack,
};

const AppContainer: React.FC<Props> = (_props) => {
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const {
    isSignInComplete,
    toastData,
    showToast,
    showToastMessage,
    resetToastMessage,
    setFilters,
    setIsInternetReachable,
    isInternetReachable,
    storageService,
    assessmentStore,
    assessmentFormStore,
    currentlyEditingAssessment,
    setIsGlobalAutosaving,
  } = store;
  const [key, setKey] = useState<keyof typeof Stacks>(
    isSignInComplete ? 'AppStack' : 'AuthStack',
  );

  const Stack = Stacks[key];

  const linking: LinkingOptions<AppStackParamList | AuthStackParamList> = {
    prefixes: [config.bpEwellsUrl],
    config: LinkingConfiguration[key],
  };

  const wasOffline = useRef(false);
  const isFirstRender = useRef(true);
  const isFirstAutosaveRender = useRef(true);
  const userDefaultDetails = useUserDefaultDetails();

  useEffect(() => {
    const unsubscribeNetInfoListener = NetInfo.addEventListener((state) => {
      if (isFirstRender.current) {
        isFirstRender.current = false;
      } else {
        console.log(
          `[DEBUG:APP] Network state updated: ${
            state.isConnected
              ? 'Connected to network ' +
                `${
                  state.isInternetReachable
                    ? 'and has internet connection.'
                    : 'but has no internet connection.'
                }`
              : 'Disconnected from network.'
          }`,
        );
        setIsInternetReachable(state.isInternetReachable);
        resetToastMessage();
        if (!state.isInternetReachable) {
          wasOffline.current = true;
          showToastMessage(true, {
            message:
              'You are offline. Your response will be temporarily stored.\nPlease reconnect to the internet as soon as possible.',
            error: true,
            toastDuration: 0,
          });
        } else if (state.isInternetReachable) {
          if (wasOffline.current) {
            showToastMessage(true, {
              message: 'You are now reconnected to the internet.',
              success: true,
              toastDuration: 1000,
            });
          }
        }
      }
    });
    return () => unsubscribeNetInfoListener();
  }, []);

  const onSubmit = useCallback(
    async (data: AssessmentFormData, draftAssessmentId: number) => {
      if (
        loading ||
        !isInternetReachable ||
        draftAssessmentId === currentlyEditingAssessment
      ) {
        return;
      }

      setLoading(true);

      const assessment: AssessmentData = pick(data, [
        'dateObserved',
        'scheduleId',
        'performanceUnit',
        'selfVerificationType',
        'assessmentForm',
        'assessmentFormVersionId',
        'site',
        'category',
        'assessor',
        'isActive',
        'isDraft',
      ]);
      assessment.isDraft = 'true';
      const assessmentForm = assessmentFormStore.getAssessmentForm(
        Number(assessment.assessmentForm),
      )!;
      assessment.assessmentFormVersionId = `${assessmentForm.versionId}`;

      const result = await assessmentStore.updateAssessment(
        assessment,
        draftAssessmentId,
        true,
      );
      if (result.ok) {
        const assessmentId = result.extra!.id;
        const existingAnswerList: AnswerData[] = [];
        const newAnswerList: AnswerData[] = [];

        data.answers.forEach((answer: any) => {
          if (answer.id) {
            existingAnswerList.push({
              id: answer.id,
              answer: answer.answer,
              description: answer.description,
              question: answer.question,
              questionVersionId: answer.questionVersionId,
              assessment: assessmentId,
            });
          } else {
            newAnswerList.push({
              answer: answer.answer,
              description: answer.description,
              question: answer.question,
              questionVersionId: answer.questionVersionId,
              assessment: assessmentId,
            });
          }
        });

        const existingAnswerListResult = await assessmentStore.updateAnswer(
          existingAnswerList,
        );

        let newAnswerListIds = {};
        if (newAnswerList.length > 0) {
          const newAnswerListResult = await assessmentStore.addAnswer(
            newAnswerList,
          );
          newAnswerListIds = newAnswerListResult.extra!.ids;
        }

        if (existingAnswerListResult.ok) {
          const questionAndAnswerIds = {
            ...existingAnswerListResult.extra!.ids,
            ...newAnswerListIds,
          };
          const fileList: FileItem[] = [];

          data.answers.forEach((answer: AnswerForm) => {
            if (answer.attachments) {
              answer.attachments.forEach((attachment: AttachmentForm) => {
                if (!attachment.isSaved) {
                  fileList.push({
                    attachmentKey: attachment.key!,
                    attachmentName: attachment.name,
                    answer: questionAndAnswerIds[answer.question],
                  });
                }
              });
            }
          });

          if (fileList.length !== 0) {
            const chunks: FileItem[][] = chunkArray(fileList, 5);

            for (let i = 0; i < chunks.length; i++) {
              const fileListChunk = chunks[i];
              const chunkFilePromises = fileListChunk.map((fileItem) =>
                storageService.getItemAsync(fileItem.attachmentKey),
              );
              const chunkFiles = await Promise.all(chunkFilePromises);

              const filteredFileListChunk = fileListChunk.filter(
                (fileItem, index) => {
                  if (chunkFiles[index]) {
                    fileItem.attachment = chunkFiles[index]!;
                    return true;
                  }
                  return false;
                },
              );

              if (filteredFileListChunk.length > 0) {
                await assessmentStore.addAttachments(filteredFileListChunk);
              }
            }
          }

          const attachmentsToDelete: any[] = [];
          data.answers.forEach((answer: AnswerForm) => {
            if (answer.attachments) {
              answer.attachments.forEach((attachment: AttachmentForm) => {
                if (attachment.toDelete) {
                  attachmentsToDelete.push({ id: attachment.id });
                }
              });
            }
          });

          if (attachmentsToDelete.length !== 0) {
            await assessmentStore.deleteAttachments(attachmentsToDelete);
          }
        }
      }
      setLoading(false);
    },
    [loading, isInternetReachable],
  );

  const autosaveOfflineDrafts = useCallback(async () => {
    if (!isInternetReachable) {
      return;
    }

    const storedKeys = await storageService.getAllKeys();
    if (!storedKeys) {
      return;
    }

    setIsGlobalAutosaving(true);

    // get all saved form data
    const formKeys = storedKeys.filter((key: string) =>
      key.startsWith(config.formsKeyPrefix),
    );

    for (const key of formKeys) {
      const storedFormData = await storageService.getItemAsync(key);
      if (!storedFormData) {
        continue;
      }

      const { form, modified } = JSON.parse(storedFormData) as StoredFormData;
      const draftAssessmentId = Number(key.replace(config.formsKeyPrefix, ''));

      // get online modified date from api
      let onlineModifiedDateString = '';
      const result = await assessmentStore.fetchAssessmentDetails(
        draftAssessmentId,
      );
      if (result.ok && result.extra && result.extra.modified) {
        onlineModifiedDateString = result.extra.modified;
        const offlineModifiedDate = parseISO(modified);
        const onlineModifiedDate = parseISO(onlineModifiedDateString);

        if (offlineModifiedDate > onlineModifiedDate) {
          await onSubmit(form, draftAssessmentId);
        }
      } else if (
        !result.ok &&
        result.errors &&
        result.errors.detail &&
        (result.errors.detail === 'Not found.' ||
          result.errors.detail ===
            'Cannot set a submitted assessment as draft.')
      ) {
        await storageService.removeSet(key);
        await storageService.removeItemAsync(key);
      }
    }

    setIsGlobalAutosaving(false);
  }, [currentlyEditingAssessment, isInternetReachable]);

  useEffect(() => {
    if (isFirstAutosaveRender.current) {
      isFirstAutosaveRender.current = false;
      setIsGlobalAutosaving(false);
      return;
    }

    if (isInternetReachable) {
      autosaveOfflineDrafts();
    }
  }, [isInternetReachable]);

  useEffect(() => {
    setKey(isSignInComplete ? 'AppStack' : 'AuthStack');
  }, [isSignInComplete]);

  useEffect(() => {
    if (isSignInComplete) {
      setFilters({
        dateStart: userDefaultDetails.initialDateStart,
        dateEnd: userDefaultDetails.initialDateEnd,
        user: userDefaultDetails.id,
        site: userDefaultDetails.site,
        performanceUnit: userDefaultDetails.performanceUnit,
      });
    }
  }, [isSignInComplete]);

  return (
    <NavigationContainer
      linking={linking}
      documentTitle={{
        formatter: (options, route) =>
          `${options?.title ?? route?.name}${
            config.env !== 'production'
              ? ` (${config.env.charAt(0).toUpperCase() + config.env.slice(1)})`
              : ''
          }`,
      }}
    >
      <Stack />
      {showToast && (
        <Toast
          message={toastData?.message || ''}
          visible={showToast}
          setVisible={(_value: boolean) => showToastMessage(false, null)}
          {...toastData}
        />
      )}
    </NavigationContainer>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    headerLeftContainer: {
      marginHorizontal: 20,
    },
    headerTitle: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 18,
      letterSpacing: 1,
    },
    headerRightContainer: {
      marginHorizontal: 20,
      flexDirection: 'row',
      alignItems: 'center',
    },
    logoContainer: {
      width: 196,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logoBorder: {
      width: 1,
      height: 29,
      backgroundColor: 'white',
      marginRight: 7.5,
      marginLeft: 5,
    },
    nameContainer: {
      marginHorizontal: 20,
    },
    fullName: {
      color: '#FFFFFF',
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
    },
    notYou: {
      color: '#99CC00',
      fontFamily: 'UniversLTPro_Regular',
      fontWeight: 'bold',
      fontSize: 14,
    },
    logOut: {
      color: '#BDFC00',
      fontFamily: 'UniversLTPro_Regular',
      fontWeight: 'bold',
      fontSize: 14,
    },
    image: {
      flex: 1,
      width: 32,
      height: 32,
      borderRadius: 16,
      resizeMode: 'contain',
    },
  },
});

export default observer(AppContainer);
