import { Text, Datepicker, Icon } from '@ui-kitten/components';
import React, { useCallback, useRef } from 'react';
import { Keyboard, Pressable, View } from 'react-native';
import { useHover, useActive } from 'react-native-web-hooks';

import CalendarIcon from '../../../assets/images/calendar_icon.svg';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';

type Props = {
  label?: string;
  onSelectDate: (value: Date) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  size?: string;
  status?: string;
  placeholder?: string;
  date: Date | undefined;
  disabled?: boolean;
  showDateClear: boolean;
  onClearInput?: any;
  labelHalf?: boolean;
  min?: Date;
  max?: Date;
};

const DatePicker: React.FC<Props> = ({
  label,
  onSelectDate,
  onFocus,
  onBlur,
  size = 'large',
  status = 'primary',
  placeholder,
  date,
  disabled = false,
  showDateClear,
  onClearInput,
  labelHalf,
  min,
  max,
}) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const ref = useRef(null);

  const isHovered = useHover(ref);
  const isActive = useActive(ref);

  const onPressIn = () => {
    Keyboard.dismiss();
  };

  const renderCloseIcon = useCallback(
    (props: any) =>
      showDateClear ? (
        <Pressable
          onPress={() => {
            onClearInput();
          }}
        >
          <Icon name="close" {...props} />
        </Pressable>
      ) : (
        <CalendarIcon />
      ),
    [showDateClear],
  );

  return (
    <View style={styles.container} ref={ref}>
      {label && (
        <View style={styles.label}>
          {labelHalf && <View style={styles.labelHalf} />}
          <Text
            style={[
              styles.labelText,
              isActive && !disabled
                ? styles.active
                : isHovered && !disabled
                ? styles.hover
                : styles.inactive,
            ]}
          >
            {label}
          </Text>
        </View>
      )}

      <View
        style={[
          styles.dateContainer,
          isActive ? styles.active : isHovered ? styles.hover : styles.inactive,
        ]}
      >
        <Datepicker
          placeholder={placeholder}
          date={date}
          size={size}
          status={status}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
          onSelect={onSelectDate}
          onPressIn={onPressIn}
          accessoryRight={renderCloseIcon}
          min={min}
          max={max}
        />
      </View>
    </View>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flex: 1,
      position: 'relative',
      marginTop: 21,
      backgroundColor: 'input-field-background',
    },
    label: {
      flex: 1,
      position: 'absolute',
      backgroundColor: 'input-field-background',
      left: 7,
      top: -8,
      paddingHorizontal: 5,
      paddingVertical: 0,
      margin: 0,
      zIndex: 1,
    },
    labelHalf: {
      height: '50%',
      backgroundColor: 'input-field-background-focused',
      width: '100%',
      position: 'absolute',
      left: 0,
    },
    active: {
      color: 'input-field-outline-active',
      borderColor: 'input-field-outline-active',
    },
    hover: {
      color: 'input-field-outline-hover',
      borderColor: 'input-field-outline-hover',
    },
    inactive: {
      color: 'text-grey',
      borderColor: 'input-field-outline',
    },
    labelText: {
      fontSize: 14,
      fontFamily: 'UniversLTPro_Regular',
      color: 'input-field-outline',
      zIndex: 2,
    },
    dateContainer: {
      alignSelf: 'stretch',
      borderRadius: 3,
      borderWidth: 1,
      backgroundColor: 'input-field-background',
    },
  },
});

export default DatePicker;
