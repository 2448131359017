import { observable } from 'mobx';
import {
  model,
  Model,
  _async,
  _await,
  modelFlow,
  objectMap,
  getRoot,
  prop,
  modelAction,
  ModelCreationData,
} from 'mobx-keystone';

import Store from './Store';
import SiteType from '../models/SiteType';
import * as api from '../services/api';
import { getError, getSuccess } from '../utils/models';

@model('bpEwells/SiteTypeStore')
export default class SiteTypeStore extends Model({
  siteTypes: prop(() => objectMap<SiteType>()),
}) {
  @observable
  loading = false;

  getSiteType = (id: number): SiteType | undefined => {
    return this.siteTypes.get(`${id}`);
  };

  getSiteTypes = (): SiteType[] => {
    return Array.from(this.siteTypes.values());
  };

  getActiveSiteTypes = (): SiteType[] => {
    return Array.from(this.siteTypes.values()).filter((s) => s.isActive);
  };

  @modelAction
  createOrUpdateSiteType(data: ModelCreationData<SiteType>) {
    const id = `${data.id}`;

    let siteType: SiteType;
    if (this.siteTypes.has(id)) {
      siteType = this.siteTypes.get(id)!;
    } else {
      siteType = new SiteType(data);
      this.siteTypes.set(id, siteType);
    }

    siteType.update(data);
  }

  @modelFlow
  fetchSiteTypes = _async(function* (this: SiteTypeStore) {
    const rootStore = getRoot<Store>(this);

    if (!rootStore.authStore || !rootStore.authStore.accessToken) {
      return getSuccess();
    }

    this.loading = true;

    let results: ModelCreationData<SiteType>[];
    try {
      ({
        response: {
          entities: { results },
        },
      } = yield* _await(api.fetchSiteTypes(rootStore.authStore.accessToken)));
    } catch (error) {
      console.warn('[DEBUG] error fetching site types', error);
      yield* _await(rootStore.authStore.checkToken(error));
      return getError(error);
    }

    results.forEach((data) => this.createOrUpdateSiteType(data));

    this.loading = false;
    return getSuccess();
  });
}
