import { useIsFocused } from '@react-navigation/native';
import { Button, Divider, Icon, Text, useTheme } from '@ui-kitten/components';
import { parseISO } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Animated, Easing, Pressable, View } from 'react-native';

import AutocompleteComponent from './AutocompleteComponent';
import DatePicker from './DatePicker';
import { CatActionStatus } from '../../constants/Status';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import useUserDefaultDetails from '../../hooks/useUserDefaultDetails';
import { useStore } from '../../stores';
import { FilterData } from '../../types';
import { useUserNameEmail } from '../../utils/helper';

type Props = {
  conformanceActionTracker?: boolean;
  onFilterSubmit?: (data: any) => void;
  filterIndex?: number;
  actualIndex?: number;
};

const ShowFilters: React.FC<Props> = ({
  conformanceActionTracker,
  onFilterSubmit = () => {},
  filterIndex,
  actualIndex,
}) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const [isActive, setIsActive] = useState(false);

  const store = useStore();
  const {
    performanceUnitStore,
    siteStore,
    selfVerificationTypeStore,
    categoryStore,
    assessmentFormStore,
    userStore,
    siteTypeStore,
    actionStore,
    setFilters,
  } = store;

  const { register, setValue, handleSubmit, control } = useForm<FilterData>();
  const theme = useTheme();
  const userNameEmail = useUserNameEmail();
  const isFocused = useIsFocused();
  const filters = store.filters ? store.filters : {};
  const userDefaultDetails = useUserDefaultDetails();
  const isFirstRenderPu = useRef(true);
  const isFirstRenderSvt = useRef(true);
  const [showDateStartClear, setShowDateStartClear] = useState<boolean>(
    !!filters.dateStart,
  );
  const [showDateEndClear, setShowDateEndClear] = useState<boolean>(
    !!filters.dateEnd,
  );

  const dateStart = useWatch({
    name: 'dateStart',
    control,
  });
  const dateEnd = useWatch({
    name: 'dateEnd',
    control,
  });
  const performanceUnit = useWatch({
    name: 'performanceUnit',
    defaultValue: filters.performanceUnit,
    control,
  });
  const selfVerificationType = useWatch({
    name: 'selfVerificationType',
    defaultValue: filters.selfVerificationType,
    control,
  });
  const assessmentForm = useWatch({
    name: 'assessmentForm',
    defaultValue: filters.assessmentForm,
    control,
  });
  const site = useWatch({ name: 'site', defaultValue: filters.site, control });
  const category = useWatch({
    name: 'category',
    defaultValue: filters.category,
    control,
  });
  const user = useWatch({ name: 'user', defaultValue: filters.user, control });
  const status = useWatch({
    name: 'status',
    defaultValue: filters.status,
    control,
  });
  const owner = useWatch({
    name: 'owner',
    defaultValue: filters.owner,
    control,
  });
  const siteType = useWatch({
    name: 'siteType',
    defaultValue: filters.siteType,
    control,
  });

  const performanceUnits = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    performanceUnitStore
      .getActivePerformanceUnits()
      .forEach((performanceUnit) => {
        values.push({
          id: performanceUnit.id.toString(),
          text: performanceUnit.name,
        });
      });
    return values;
  }, [performanceUnitStore.performanceUnits.values()]);

  const sites = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    siteStore.getActiveSites(Number(performanceUnit)).forEach((site) => {
      values.push({
        id: site.id.toString(),
        text: site.name,
      });
    });
    return values;
  }, [siteStore.sites.values(), performanceUnit]);

  const selfVerificationTypes = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    selfVerificationTypeStore
      .getActiveSelfVerificationTypes()
      .forEach((selfVerificationType) => {
        values.push({
          id: selfVerificationType.id.toString(),
          text: selfVerificationType.name,
        });
      });
    return values;
  }, [selfVerificationTypeStore.selfVerificationTypes.values()]);

  const categories = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    categoryStore
      .getActiveCategories(Number(selfVerificationType))
      .forEach((category) => {
        values.push({
          id: category.id.toString(),
          text: category.name,
        });
      });
    return values;
  }, [categoryStore.categories.values(), selfVerificationType]);

  const assessmentForms = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    assessmentFormStore
      .getActiveAssessmentForms(Number(selfVerificationType), Number(category))
      .forEach((assessmentForm) => {
        values.push({
          id: assessmentForm.id.toString(),
          text: assessmentForm.name,
        });
      });
    return values;
  }, [assessmentFormStore.assessmentForms.values()]);

  const users = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    userStore.getUsers({}).forEach((user) => {
      values.push({
        id: user.id.toString(),
        text: `${user.firstName} ${user.lastName}`,
      });
    });
    return values;
  }, [userStore.users.values()]);

  const siteTypes = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    siteTypeStore.getActiveSiteTypes().forEach((siteType) => {
      values.push({
        id: siteType.id.toString(),
        text: siteType.name,
      });
    });
    return values;
  }, [siteTypeStore.siteTypes.values()]);

  const catActionsStatus = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    const enumStatus = [...Object.values(CatActionStatus), 'Overdue'];
    enumStatus.forEach((cat, index) => {
      values.push({
        id: cat,
        text: cat,
      });
    });
    return values;
  }, [CatActionStatus]);

  const catActionsOwner = useMemo(() => {
    const values: { id: string; text: string }[] = [];
    const catActionsGeneral = actionStore.getCatActions();
    const catActionsOwner = [
      ...new Set(catActionsGeneral.map((item) => item.owner)),
    ];
    catActionsOwner.forEach((cat, index) => {
      values.push({
        id: cat,
        text: cat,
      });
    });
    return values;
  }, [actionStore.catActions.values()]);

  useEffect(() => {
    register('dateStart', {
      required: false,
      value: filters.dateStart,
    });
    register('dateEnd', {
      required: false,
      value: filters.dateEnd,
    });
    register('performanceUnit', {
      required: false,
      value: filters.performanceUnit,
    });
    register('selfVerificationType', {
      required: false,
      value: filters.selfVerificationType,
    });
    register('assessmentForm', {
      required: false,
      value: filters.assessmentForm,
    });
    register('site', { required: false, value: filters.site });
    register('category', { required: false, value: filters.category });
    register('user', { required: false, value: filters.user });
    register('siteType', { required: false, value: filters.siteType });
    register('status', { required: false, value: filters.status });
    register('owner', { required: false, value: filters.owner });
  }, [register]);

  const onReset = () => {
    setIsActive(false);
    isFirstRenderPu.current = true;
    isFirstRenderSvt.current = true;
    setValue('dateStart', userDefaultDetails.initialDateStart);
    setValue('dateEnd', userDefaultDetails.initialDateEnd);
    setValue('performanceUnit', `${userDefaultDetails.performanceUnit}`);
    setValue('selfVerificationType', '');
    setValue('user', userDefaultDetails.id);
    setValue('siteType', '');
    setValue('status', '');
    setValue('owner', '');
    setValue('site', userDefaultDetails.site);
    setValue('category', '');
    setValue('assessmentForm', '');
    setShowDateStartClear(true);
    setShowDateEndClear(true);
    onSubmitWrapped();
  };

  useEffect(() => {
    if (
      (actualIndex &&
        actualIndex >= 0 &&
        isFocused &&
        filterIndex === actualIndex) ||
      isFocused
    ) {
      setIsActive(false);
      isFirstRenderPu.current = true;
      isFirstRenderSvt.current = true;
      setValue('dateStart', filters.dateStart);
      setValue('dateEnd', filters.dateEnd);
      setValue('performanceUnit', filters.performanceUnit);
      setValue('selfVerificationType', filters.selfVerificationType);
      setValue('user', filters.user);
      setValue('siteType', filters.siteType);
      setValue('status', filters.status);
      setValue('owner', filters.owner);
      setValue('site', filters.site);
      setValue('category', filters.category);
      setValue('assessmentForm', filters.assessmentForm);
    }
  }, [isFocused, actualIndex]);

  useEffect(() => {
    if (isFirstRenderPu.current) {
      isFirstRenderPu.current = false;
      return;
    }
    setValue('site', '');
  }, [performanceUnit]);

  useEffect(() => {
    if (isFirstRenderSvt.current) {
      isFirstRenderSvt.current = false;
      return;
    }
    setValue('category', '');
  }, [selfVerificationType]);

  useEffect(() => {
    setValue('assessmentForm', '');
  }, [selfVerificationType, category]);

  const onSubmit = useCallback(async (data: any) => {
    setFilters(data);
    setIsActive(false);
    onFilterSubmit(data);
  }, []);

  const onSubmitWrapped = handleSubmit(onSubmit);

  const spinValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(spinValue, {
      duration: 200,
      easing: Easing.linear,
      toValue: isActive ? 1 : 0,
      useNativeDriver: true,
    }).start();
  }, [isActive]);

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '-180deg'],
  });

  const minDate = new Date(2021, 0, 1);
  const maxDate = new Date(new Date().getFullYear() + 1, 11, 31);

  return (
    <>
      <>
        <View
          style={[
            styles.mainHideFiltersContainer,
            { height: isActive ? 490 : 56 },
          ]}
        >
          <Pressable
            style={styles.hideFiltersContainer}
            onPress={() => setIsActive(!isActive)}
          >
            <View style={styles.showFilters}>
              <Text style={[styles.text, { paddingRight: isActive ? 25 : 16 }]}>
                {isActive ? 'Hide Filters' : 'Show Filters'}
              </Text>
              <Animated.View style={{ transform: [{ rotate: spin }] }}>
                <Icon
                  name="chevron-down-outline"
                  width={32}
                  height={32}
                  fill="black"
                />
              </Animated.View>
            </View>
          </Pressable>
          {isActive && (
            <>
              <View style={[styles.filtersRow]}>
                <DatePicker
                  label="Assessment start date"
                  placeholder="Select assessment start date"
                  date={dateStart ? parseISO(dateStart) : undefined}
                  onSelectDate={(date) => {
                    setValue('dateStart', date.toISOString());
                    setShowDateStartClear(true);
                  }}
                  showDateClear={showDateStartClear}
                  onClearInput={() => {
                    setValue('dateStart', undefined);
                    setShowDateStartClear(false);
                  }}
                  min={minDate}
                  max={maxDate}
                />
                <View style={styles.gap} />
                <DatePicker
                  label="Assessment end date"
                  placeholder="Select assessment end date"
                  date={dateEnd ? parseISO(dateEnd) : undefined}
                  onSelectDate={(date) => {
                    setValue('dateEnd', date.toISOString());
                    setShowDateEndClear(true);
                  }}
                  showDateClear={showDateEndClear}
                  onClearInput={() => {
                    setValue('dateEnd', undefined);
                    setShowDateEndClear(false);
                  }}
                  min={minDate}
                  max={maxDate}
                />
              </View>
              <View style={styles.filtersRow}>
                <AutocompleteComponent
                  label="Performance Unit"
                  placeholder="Select performance unit"
                  onSelect={(id) => {
                    setValue('performanceUnit', id);
                    setValue('site', '');
                  }}
                  data={performanceUnits}
                  value={performanceUnit}
                />
                <View style={styles.gap} />
                <AutocompleteComponent
                  label="Site"
                  placeholder="Select site"
                  onSelect={(id) => setValue('site', id)}
                  data={sites}
                  value={site}
                />
              </View>
              <View style={styles.filtersRow}>
                <AutocompleteComponent
                  label="Self-verification type"
                  placeholder="Select self-verification type"
                  onSelect={(id) => {
                    setValue('selfVerificationType', id);
                    setValue('category', '');
                  }}
                  data={selfVerificationTypes}
                  value={selfVerificationType}
                />
                <View style={styles.gap} />
                <AutocompleteComponent
                  label="Category"
                  placeholder="Select category"
                  onSelect={(id) => setValue('category', id)}
                  data={categories}
                  value={category}
                />
              </View>
              <Divider style={styles.divider} />
              <Text style={styles.text}>Advanced filters</Text>
              <View style={styles.filtersRow}>
                <AutocompleteComponent
                  label="Assessment form"
                  placeholder="Select assessment form"
                  onSelect={(id) => setValue('assessmentForm', id)}
                  data={assessmentForms}
                  value={assessmentForm}
                />
                <View style={styles.gap} />
                {conformanceActionTracker ? (
                  <AutocompleteComponent
                    label="Action owner"
                    placeholder="Select action owner"
                    onSelect={(id) => setValue('owner', id)}
                    data={catActionsOwner}
                    value={owner}
                  />
                ) : (
                  <AutocompleteComponent
                    label="Assessor"
                    placeholder="Select assessor"
                    onSelect={(id) => setValue('user', id)}
                    data={users}
                    value={user}
                    initialValue={userNameEmail}
                  />
                )}
              </View>
              <View style={styles.filtersRow}>
                {conformanceActionTracker ? (
                  <AutocompleteComponent
                    label="Action status"
                    placeholder="Select action status"
                    onSelect={(id) => setValue('status', id)}
                    data={catActionsStatus}
                    value={status}
                  />
                ) : (
                  <AutocompleteComponent
                    label="Site type"
                    placeholder="Select site type"
                    onSelect={(id) => setValue('siteType', id)}
                    data={siteTypes}
                    value={siteType}
                  />
                )}
                <View style={styles.gap} />
                <View style={styles.empty} />
              </View>
            </>
          )}
        </View>
        {isActive && (
          <View style={styles.buttonsContainer}>
            <Button
              status="basic"
              size="large"
              style={styles.button}
              accessoryLeft={() => (
                <Icon
                  name="close-outline"
                  width={24}
                  height={24}
                  fill="text-dark"
                />
              )}
              onPress={onReset}
            >
              Reset
            </Button>
            <Button
              status="primary"
              size="large"
              style={styles.button}
              accessoryLeft={() => (
                <Icon
                  name="search-outline"
                  width={24}
                  height={24}
                  fill={theme['text-white']}
                />
              )}
              onPress={onSubmitWrapped}
            >
              Search
            </Button>
          </View>
        )}
      </>
    </>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    mainHideFiltersContainer: {
      backgroundColor: '#FFFFFF',
      marginTop: 16,
      paddingVertical: 12,
      paddingHorizontal: 24,
      borderRadius: 4,
    },
    hideFiltersContainer: {
      flexDirection: 'row',
    },
    showFilters: {
      flexDirection: 'row',
      alignItems: 'center',
      width: 141,
    },
    filtersRow: {
      flexDirection: 'row',
    },
    gap: {
      width: 25,
    },
    divider: {
      marginVertical: 16,
      backgroundColor: '#99CC00',
    },
    text: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
    },
    buttonsContainer: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: 16,
    },
    button: {
      width: 312,
      borderRadius: 4,
      justifyContent: 'flex-start',
      marginLeft: 18,
    },
    empty: {
      flex: 1,
    },
  },
});

export default observer(ShowFilters);
