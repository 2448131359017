import { Button, Icon, Text, useTheme } from '@ui-kitten/components';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo, useState } from 'react';
import { FlatList, Linking, Pressable, View } from 'react-native';
import Markdown, { MarkdownIt } from 'react-native-markdown-display';
import TurndownService from 'turndown';

import ViewCommentBox from './ViewCommentBox';
import SmallGalleryIcon from '../../../assets/images/gallery_small.svg';
import config from '../../config';
import useResponsiveStyleSheet, {
  createResponsiveStyle,
} from '../../hooks/useResponsiveStyleSheet';
import HistoricalQuestion from '../../models/HistoricalQuestion';
import { useStore } from '../../stores';
import { AttachmentForm, ImageAttachment } from '../../types';
import { isExtImage } from '../../utils/helper';
import CustomLinking from '../../utils/linking';
import CustomModal from '../Common/CustomModal';
import ImageViewer from '../Common/ImageViewer';

type Props = {
  assessmentId: number | undefined;
  question: HistoricalQuestion;
  index: any;
};

const ViewQuestionFill: React.FC<Props> = ({
  question,
  assessmentId,
  index,
}) => {
  const styles = useResponsiveStyleSheet(themedStyles);
  const [isImageVisible, setIsImageVisible] = useState(false);
  const [imageAttachment, setImageAttachment] = useState<ImageAttachment>();
  const theme = useTheme();
  const markdownItInstance = MarkdownIt({ typographer: true, linkify: true });
  const tdservice = new TurndownService();
  tdservice.escape = (string) => string;

  const store = useStore();
  const { assessmentStore } = store;
  const answerModel = useMemo(
    () =>
      assessmentStore
        .getAnswers()
        .find(
          (answer) =>
            answer.assessment === assessmentId &&
            answer.question === question.id &&
            answer.questionVersionId === question.versionId.toString(),
        ),
    [assessmentId],
  );
  const attachments = assessmentStore
    .getAttachments()
    .filter((attachment) => attachment.answer === answerModel?.id);

  const answer = answerModel?.answer || '';
  const comment = answerModel?.description || '';
  const attachmentList: AttachmentForm[] = [];

  attachments.forEach((attachment) => {
    const apiUrl = config.urls.api.replace('api/', '');
    const attachmentUrl =
      config.env === 'local'
        ? `${apiUrl}${attachment.attachment.substring(1)}`
        : attachment.attachment;
    const ext = attachment.attachmentName.split('.').pop();
    attachmentList.push({
      file: attachmentUrl,
      name: attachment.attachmentName,
      isImage: isExtImage(ext),
      isSaved: true,
    });
  });

  const onFilePress = async (item: AttachmentForm) => {
    const openImageViewer = async (file: string) => {
      setImageAttachment({ ...item, file });
      setIsImageVisible(true);
    };

    if (item.isImage) {
      await openImageViewer(item.file!);
    } else {
      const canOpen = await Linking.canOpenURL(item.file!);
      if (canOpen) {
        CustomLinking.openURL(item.file!, '_blank');
      }
    }
  };

  const renderFileItem = useCallback(({ item }: { item: AttachmentForm }) => {
    return (
      item && (
        <View style={styles.fileView}>
          <Pressable
            onPress={() => onFilePress(item)}
            style={{ flexDirection: 'row' }}
          >
            <View style={styles.icon}>
              {!item.isImage ? (
                <Icon
                  name="arrow-downward-outline"
                  width={22}
                  height={22}
                  fill={theme['text-dark']}
                />
              ) : (
                <SmallGalleryIcon />
              )}
            </View>
            <View style={[styles.fileName, { width: 120 }]}>
              <Text numberOfLines={1} style={styles.fileText}>
                {item.name}
              </Text>
            </View>
          </Pressable>
        </View>
      )
    );
  }, []);

  const renderImageViewer = () => (
    <ImageViewer
      attachment={imageAttachment!}
      setIsImageVisible={setIsImageVisible}
    />
  );

  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <View style={styles.questionContainer}>
          <Text style={styles.questionNumber}>
            Question {index + 1}{' '}
            {question.isDescriptionRequired ? '(comment required)' : ''}
          </Text>
          <Markdown
            style={{
              body: styles.question,
            }}
            markdownit={markdownItInstance}
          >
            {tdservice.turndown(
              question.question.replace(/(?:\r\n|\r|\n)/g, '<br>'),
            )}
          </Markdown>
          <Markdown
            style={{
              body: styles.description,
            }}
            markdownit={markdownItInstance}
          >
            {tdservice.turndown(
              question.description.replace(/(?:\r\n|\r|\n)/g, '<br>'),
            )}
          </Markdown>
        </View>
        <View style={styles.buttonContainer}>
          {answer === 'Yes' ? (
            <Button
              status="success"
              size="medium"
              appearance="outline"
              disabled
              style={[
                { marginBottom: 8 },
                answer === 'Yes' && styles.yesButton,
              ]}
            >
              Yes
            </Button>
          ) : answer === 'No' ? (
            <Button
              status="danger"
              size="medium"
              appearance="outline"
              disabled
              style={[{ marginBottom: 8 }, answer === 'No' && styles.noButton]}
            >
              No
            </Button>
          ) : answer === 'N/A' ? (
            <Button
              status="info"
              size="medium"
              appearance="outline"
              disabled
              style={[answer === 'N/A' && styles.naButton]}
            >
              N/A
            </Button>
          ) : (
            <Button status="info" size="medium" appearance="outline" disabled>
              No Answer
            </Button>
          )}
        </View>
      </View>
      <View style={styles.right}>
        <View style={styles.commentContainer}>
          <ViewCommentBox comment={comment} />
        </View>
        <View style={styles.photosContainer}>
          <FlatList
            style={styles.fileList}
            data={attachmentList}
            renderItem={renderFileItem}
            showsHorizontalScrollIndicator={false}
          />
        </View>
      </View>
      <CustomModal
        visible={isImageVisible}
        onBackdropPress={() => setIsImageVisible(false)}
        children={renderImageViewer()}
      />
    </View>
  );
};

const themedStyles = createResponsiveStyle({
  baseStyle: {
    container: {
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
      paddingLeft: 16,
      paddingRight: 19,
      paddingBottom: 32,
      paddingTop: 6,
      backgroundColor: 'input-field-background',
      alignContent: 'stretch',
      minHeight: 168,
    },
    left: {
      flex: 0.46,
      flexDirection: 'row',
      paddingRight: 32,
    },
    right: {
      flex: 0.54,
      flexDirection: 'row',
    },
    questionContainer: {
      paddingRight: 40,
      paddingTop: 18,
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    questionNumber: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 14,
      color: 'text-dark',
    },
    question: {
      fontFamily: 'UniversBP_Light',
      fontWeight: 'bold',
      fontSize: 16,
      color: 'text-dark',
    },
    description: {
      paddingTop: 8,
      fontFamily: 'UniversLTPro_Regular',
      fontSize: 14,
      color: 'blue-01',
    },
    buttonContainer: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingTop: 28,
      flexBasis: 144,
    },
    yesButton: {
      borderColor: 'button-success-outline-active',
      backgroundColor: 'button-success-background-active',
    },
    noButton: {
      borderColor: 'button-danger-outline-active',
      backgroundColor: 'button-danger-background-active',
    },
    naButton: {
      borderColor: 'button-info-outline-active',
      backgroundColor: 'button-info-background-active',
    },
    commentContainer: {
      flex: 1,
      flexDirection: 'column',
      paddingRight: 32,
    },
    photosContainer: {
      width: 150,
      flexDirection: 'column',
      paddingTop: 28,
    },
    backdrop: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 0,
    },
    icon: {
      width: 20,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 5,
    },
    fileView: {
      flexDirection: 'row',
      width: 150,
      height: 22,
      marginBottom: 9,
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
    fileText: {
      fontFamily: 'UniversBP_Regular',
      fontWeight: 'normal',
      fontSize: 14,
      textAlign: 'left',
    },
    fileList: {
      marginTop: 17,
      flex: 1,
    },
    fileName: {
      overflow: 'hidden',
      height: 22,
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
});

export default observer(ViewQuestionFill);
